import _ from 'lodash'
import Utils from '../../../lib/utils'
import { IncludesFilter } from '../../../filters/includes.filter'
import { preventOverscroll } from '../../../lib/dom/scroll'
import { createSortable } from '../../../lib/dom/sortable'
import { ReportParamsMetricSelectModelFactory } from './report-params-metric-select-model'

###*
@typedef {import('angular').IFilterService} IFilterService
@typedef {import('angular').promisetracker.PromiseTrackerService} IPromiseTrackerService
@typedef {import('angular').promisetracker.PromiseTracker} IPromiseTracker
###

module = angular.module('42.controllers.scheduling.common')


module.directive 'reportParamsMetricSelect', do -> \
###*
@param { IFilterService } $filter
@param { IPromiseTrackerService } promiseTracker
@param { {
    report: {
        updateInvalidFields: (fields: { [key: string]: boolean }) => void
    }
} } ReportingState
@param { import('./report-params-metric-select-model').IReportParamsMetricSelectModelFactory } ReportParamsMetricSelectModel
@returns {angular.IDirective<angular.IScope & {
    getAvailableInfo: () => string;
    model: import('./report-params-metric-select-model').IReportParamsMetricSelectModel;
    actions: {
        reset: () => void;
        remove: (item: any) => void;
        select: (item: any) => void;
        selectAll: () => void;
    };
    tracker: IPromiseTracker;
    view: {
        filter: string;
        available: (import('./report-params-metric-select-model').IReportParamsMetrics)[];
        selected: (import('./report-params-metric-select-model').IReportParamsMetrics)[];
    }
}>}
###
return ($filter, promiseTracker, ReportingState, ReportParamsMetricSelectModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-metric-select" style="position:relative" promise-tracker="tracker">
        <header>
            <h1>What metrics should be included?</h1>
        </header>
        <main>
            <section class="available">
                <header>
                    <div class="row row-title">
                        <div class="search-container" ng-class="{filtered:view.filter.length > 0}">
                            <i class="icon-clear-filter icon-cancel-circled" ng-click="view.filter = ''"></i>
                            <input type="text" placeholder="Filter..." ng-model="view.filter"></input>
                            <i class="icon-search"></i>
                        </div>
                    </div>
                    <div class="row row-info">
                        <span class="info available-info">{{ getAvailableInfo() }}</span>
                        <button class="button-bare" ng-click="actions.selectAll()" ng-if="view.available.length > 0">select all</button>
                    </div>
                </header>
                <main>
                    <ul class="metric-groups">
                        <li class="metric-group"
                        ng-repeat="metricGroup in view.available track by metricGroup.id"
                        ng-click="actions.select(metricGroup)">
                            <div class="metrics-container">
                                <span class="label">{{ metricGroup.label }}</span>
                                <span class="metrics">
                                    <span class="metric" ng-repeat="metric in metricGroup.metrics track by metric.field">{{ metric.headerName }}</span>
                                </span>
                                <i class="move-icon move-icon-right icon-right-open-mini"></i>
                            </div>
                        </li>
                    </ul>
                </main>
            </section>
            <section class="selected">
                <header>
                    <div class="row row-title">
                        <h1>Selected</h1>
                    </div>
                    <div class="row row-info">
                        <span class="info selected-info" ng-if="model.selected.length > 1">{{ model.selected.length }} metrics selected</span>
                        <span class="info selected-info" ng-if="model.selected.length == 1">{{ model.selected.length }} metric selected</span>
                        <span class="info selected-info" ng-if="model.selected.length == 0">Click a metric on the left to select it</span>
                        <button class="button-bare" ng-click="actions.reset()" ng-if="model.selected.length > 0">reset</button>
                    </div>
                </header>
                <main>
                    <ul class="metric-groups sortable-ui">
                        <li class="metric-group" ng-repeat="metricGroup in view.selected track by metricGroup.id">
                            <div>
                                <div class="metrics-container" ng-click="actions.remove(metricGroup)">
                                    <i class="move-icon move-icon-left icon-left-open-mini"></i>
                                    <span class="label">{{ metricGroup.label }}</span>
                                    <span class="metrics">
                                        <span class="metric" ng-repeat="metric in metricGroup.metrics track by metric.field">{{ metric.headerName }}</span>
                                    </span>
                                </div>
                                <div class="icon-drag-container drag-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24">
                                        <path fill="none" d="M0 0h24v24H0V0z"/><path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
                                    </svg>
                                </div>
                            </div>
                        </li>
                    </ul>
                </main>
            </section>
        </main>
    </article>
    """
    link: (scope, element) ->
        cleanupSortable = do ->
            sortable = null
            setTimeout (->
                sortableEl = element[0]?.querySelector('.sortable-ui')
                throw new Error("element not found: .sortable-ui") if not sortableEl
                sortable = createSortable sortableEl,
                    ghostClass: 'placeholder'
                    draggable: '.metric-group'
                    dragClass: 'dragging'
                    handle: '.icon-drag-container'
                    fallbackTolerance: 2
                    forceFallback: true
                    # group: {name: scope.group, put: ["selected", "properties"]}
                    onStart: ->
                        console.log("[reporting][metrics]", "dragging started")
                        document.querySelector('.report-params-metric-select')?.classList.add('dragging')
                        return
                    onEnd: ->
                        console.log("[reporting][metrics]", "dragging ended")
                        document.querySelector('.report-params-metric-select')?.classList.remove('dragging')
                        return
                    onUpdate: (evt) ->
                        return if typeof evt.newIndex isnt 'number' or typeof evt.oldIndex isnt 'number'
                        console.log("[reporting][metrics]", "update", evt.oldIndex, evt.newIndex)
                        scope.model.selected = Utils.Array.move(scope.model.selected, evt.oldIndex, evt.newIndex)
                        return
            ), 200
            return ->
                document.querySelector('.report-params-metric-select')?.classList.remove('dragging')
                sortable?.destroy()
        scope.$on('$destroy', cleanupSortable)

        watchers = []
        scope.tracker = promiseTracker()

        scope.view = {filter:"", available:[], selected:[]}
        scope.$watch 'model.selected', (selected) ->
            scope.view.selected = _.cloneDeep(selected ? [])
            console.log("[reporting][metrics]", scope.view.selected)
            return

        listElements =
            available: $($(element).find('.available main'))
            selected:  $($(element).find('.selected main'))

        Object.values(listElements).forEach (el) ->
            unsub = preventOverscroll(el)
            scope.$on('$destroy', unsub)

        updateAvailableView = ->
            scope.view.available = do ->
                return [] if not scope.model?.available
                available = scope.model.available.filter (x) -> not scope.model.isSelected(x)
                return do ->
                    filter = scope.view.filter.toLowerCase().trim()
                    return available if not filter
                    return available.filter (metricGroup) ->
                        value = metricGroup.label.toLowerCase().trim()
                        return IncludesFilter.parse(value, filter).length > 0

        scope.getAvailableInfo = ->
            {view, model} = scope
            metrics = if view.available.length is 1 then "metric" else "metrics"
            filtered = if view.filter then "found (#{ model.available.length - model.selected.length - view.available.length } filtered)" else "available"
            count = if view.available.length is 0 then "No" else view.available.length
            return "#{count} #{metrics} #{filtered}"

        scope.actions =
            reset: ->
                scope.model.reset()
                updateAvailableView()
            remove: (item) ->
                scope.model.remove(item)
                updateAvailableView()
            select: (item) ->
                scope.model.select(item)
                setTimeout((() -> listElements.selected.scrollTop(listElements.selected[0].scrollHeight) if listElements.selected[0]), 0)
                updateAvailableView()
            selectAll: ->
                scope.view.available.forEach (x) -> scope.model.select(x)
                updateAvailableView()

        scope.$watch 'view.filter', updateAvailableView

        scope.$watch 'model.selected.length', ->
            return if not scope.model
            ReportingState.report.updateInvalidFields(scope.model.getInvalidFields())

        initModel = do ->
            promise = null
            return (params) ->
                scope.model ?= new ReportParamsMetricSelectModel(params)
                promise ?= scope.model.init()
                return promise.then ->
                    scope.model.updateModelFromParams(params)
                    return scope.model

        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            watchers.forEach (x) -> x() # unregisters the existing watchers
            watchers = []

            modelPromise = initModel(params)
            scope.tracker.addPromise(modelPromise)

            modelPromise.then ->
                updateAvailableView()
                watchers.push \
                scope.$watch 'model.selected', ((properties) ->
                    return if _.isUndefined(properties)
                    scope.model.updateParamsFromModel()
                ), true
                watchers.push \
                scope.$watch 'params.metrics', (->
                    scope.model.updateModelFromParams()
                ), true
                watchers.push \
                scope.$watch 'params.currency', ((currency) ->
                    return if not currency
                    scope.model.refresh().then(updateAvailableView)
                ), true


module.directive 'reportParamsViewerMetricSelect', do -> \
###*
@typedef {import('./report-params-metric-select-model').IReportParamsMetrics} IReportParamsMetrics
@param { import('./report-params-metric-select-model').IReportParamsMetricSelectModelFactory } ReportParamsMetricSelectModel
@returns {angular.IDirective<angular.IScope & {
    view: { metrics: IReportParamsMetrics[] }
    model: import('./report-params-metric-select-model').IReportParamsMetricSelectModel;
}>}
###
return (ReportParamsMetricSelectModel) ->
    restrict: 'E'
    scope:
        params: '='
    # replace: true
    template: \
    """
    <article class="report-params-viewer report-params-metric-select-viewer" ng-if="view.metrics && view.metrics.length > 0">
        <header>
            <h1>Selected Metrics</h1>
        </header>
        <main>
            <ul class="ui-pellets">
                <li class="ui-pellet active disabled" ng-repeat="x in view.metrics">
                    <span class="ui-pellet-value">{{ x.label }}</span>
                    <span class="ui-pellet-detail metric" ng-repeat="metric in x.metrics">{{ metric.headerName }}</span>
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope) ->
        scope.view = { metrics: [] }

        initModel = do ->
            promise = null
            return (params) ->
                scope.model ?= new ReportParamsMetricSelectModel(params)
                promise ?= scope.model.init()
                return promise.then ->
                    scope.model.updateModelFromParams(params)
                    return scope.model

        scope.$watch 'params', (params) ->
            if params is undefined
                scope.view.metrics = []
                return

            initModel(params).then ->
                scope.view.metrics = scope.model.selected


module.factory 'ReportParamsMetricSelectModel', ReportParamsMetricSelectModelFactory()
