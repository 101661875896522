import _ from 'lodash'
import 'jstree'

import 'angular-route'
import 'angular-sanitize'
import 'angular-promise-tracker'
import { isRequestAbortedError } from './lib/api/api'
import './filters'
import './components'
import './modules'
import './directives'
import './controllers'
import './router'

window.app = app = angular.module '42.dashboard', [
    'ui'
    'ngSanitize'
    'ngRoute'
    'infinite-scroll'
    '42.filters'
    '42.routes'
    '42.config'
    '42.modules'
    '42.controllers'
    '42.directives'
    '42.components'
    'ajoslin.promise-tracker'
    'ui.bootstrap'
]

# Angular UI configuration
app.value 'ui.config', {}

# FIXME: Temporary until angular ui uses $sce for `highlight` filter.
app.config ($sceProvider) ->
    $sceProvider.enabled false

app.config ($httpProvider) ->
    # This is to prevent angular from removing keys prefixed with `$`,
    # as those are needed for the query api.
    $httpProvider.defaults.transformRequest = [
        (data) -> JSON.stringify(data)
    ]

    # Allows CORS
    $httpProvider.defaults.useXDomain = true
    delete $httpProvider.defaults.headers.common['X-Requested-With']


app.run ($rootScope) ->
    $rootScope.initialized = false

app.run (['$route', '$rootScope', '$location', ($route, $rootScope, $location) ->
    original = $location.path
    $location.path = (path, reload) ->
        if typeof reload is 'boolean' and reload is false
            lastRoute = $route.current
            unsub = $rootScope.$on '$locationChangeSuccess', ->
                $route.current = lastRoute
                unsub()
        return original.apply($location, [path])
])

app.config ($provide, $routeProvider, $locationProvider, ROUTES, CONFIG) ->
    $locationProvider.html5Mode(true)
    $routeProvider.otherwise({redirectTo: do ->
        CONFIG.routes?.overview?.url ?
        CONFIG.routes?.sales?.url ?
        ROUTES.overview?.url ?
        ROUTES.sales?.url ?
        '/'
    })
    # Workaround for showing source maps on unhandled exceptions that happen in angular
    # https://github.com/angular/angular.js/issues/5217#issuecomment-50993513
    $provide.decorator '$exceptionHandler', ($delegate) -> (exception, cause) ->
        # $delegate(exception, cause)
        setTimeout ->
            return if isRequestAbortedError(exception)
            console.error("Cause:", cause) if cause
            throw exception


app.directive 'route', ->
    restrict: 'E'
    scope:
        route: '=model'
        submenu: '@'
        showIcon: '@'
    replace: true
    template: \
    """
    <li ng-class="{'active': route.active}">
        <a ng-href="{{ link }}" ng-class="{'active': route.active}" target="{{ route.href && '_blank' }}">
            <i ng-if="showIcon" class="route-icon icon-{{ route.icon }} icon-route-{{ route.id }}"></i>
            <span class="route-label">{{ label }}</span>
        </a>
    </li>
    """
    link: (scope) ->
        scope.label = (scope.submenu and scope.route.subLabel) or scope.route.label
        # Remove last path param
        scope.link = (scope.route.href or scope.route.url).split('/:')[0]


app.run ($rootScope, $location, $route, ROUTES, CONFIG) ->
    ROUTES = do ->
        routes = _.cloneDeep(ROUTES)
        return routes if not CONFIG.routes
        Object.keys(routes).forEach (routeId) ->
            routeOverrides = CONFIG.routes?[routeId]
            return if not routeOverrides
            routeOverrides = _.pick(routeOverrides, 'label', 'url', 'icon')
            routes[routeId] = _.extend routes[routeId], routeOverrides
            routes[routeId].templates = [routes[routeId].url]
        return routes

    $rootScope.routes = ROUTES

    $rootScope.$on '$routeChangeSuccess', ->
        $rootScope.activeRoute = null
        for route in _.values($rootScope.routes)
            route.active = $route.current.originalPath is route.url
            $rootScope.activeRoute = route if route.active

    previousPagePath = null
    $rootScope.$on '$viewContentLoaded', ->
        pagePath = $location.path()
        pageName = $rootScope.activeRoute?.label or pagePath
        return if previousPagePath is pagePath
        previousPagePath = pagePath
        analytics.page pageName,
            title: pageName
            path:  pagePath
