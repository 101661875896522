import _ from 'lodash';
import { titleize } from 'inflected';
import { IPromise } from 'angular';
import * as AgGrid from '@ag-grid-community/core';
import * as Analytics from '../../lib/analytics';
import * as Auth from '../../lib/auth';
import { IQuery, IMetricDefinition } from '../../lib/types';
import { FontWidthCalculator } from '../../lib/dom/font-width-calculator';
import { IMetricsGridConfigViewsColumns, isTotalRow } from './metrics-utils';
import { FilterExpressionParser } from '../../lib/parsers/filter-expression-parser';
import type { AngularInjected } from '../../lib/angular';
import { deepStripAngularProperties } from '../../lib/angular';
import { purifyText } from '../../lib/dom/html';
import type { DashboardRootScope } from '../main-controller';
import { GridOverlayMessageRendererFactory } from '../../components/grid-overlay-message-renderer';
import { IColumnDef, MetricsFunnel, IMetricsFunnelNodeService, MetricsFunnelNode } from './metrics-funnel';
import { convertToAGGridFiltering, IAGGridFiltering } from './metrics-grid-utils';
import { MouseObserverAngular } from '../../lib/dom/mouse-observer';
import { KeyboardObserverAngular } from '../../lib/dom/keyboard-observer';
import { RowNode } from '@ag-grid-community/all-modules';
import { AuthUserModel } from '@42technologies/client-lib-auth';
import { ConfigPageExperiments } from '../../lib/config-experiments';

interface IMetricsKPIsService {
    fetch: (query: IQuery) => Promise<(IMetricDefinition & { _cellClass?: string })[]>;
}

interface IMetricsGridSort {
    field: string;
    order: 1 | -1;
}

type IMetricsPageGridDataRow = Record<string, unknown>;

export interface IMetricsFunnelRowData {
    rows: IMetricsPageGridDataRow[];
    total: IMetricsPageGridDataRow[];
}

const GRID_HEADER_ROW_HEIGHT = 40;
const GRID_DATA_ROW_HEIGHT = 60;
const GRID_DATA_ROW_HEIGHT__WITH_IMAGE = 85;

class MetricsGridData {
    rows: IMetricsPageGridDataRow[] = [];
    total: IMetricsPageGridDataRow[] = [];

    constructor(data?: IMetricsFunnelRowData) {
        const { rows, total } = data ?? { rows: [], total: [] };
        this.rows = rows;
        this.total = total;
    }

    getRows() {
        return _.cloneDeep(this.rows);
    }

    getTotal() {
        return _.cloneDeep(this.total);
    }

    getRowsAndTotal(): IMetricsFunnelRowData {
        return _.cloneDeep({
            rows: this.rows,
            total: this.total,
        });
    }
}

interface IMetricsFunnelNodeViewDirectiveScope extends angular.IScope {
    user: AuthUserModel;
    tabName: string;
    organization: string;
    grid: IMetricsFunnelNodeGridViewModel;
    model: MetricsFunnel;
    experiments: ConfigPageExperiments;
    metricsGridDataModel?: IMetricsGridDataModel;

    drilldownModel: {
        active: boolean;
        enabled: boolean;
        selectedValuesByProperty: Record<string, Record<string, (string | number)[]> | undefined>;
    };

    onColumnResize: (columnsResized: Record<string, number>) => void;
    onRowSortChange: (sort: IMetricsGridSort[]) => void;
    onFilterChanged: (columnFilters: IAGGridFiltering) => void;

    enableClearFilters: boolean;
    clearFilters: () => void;

    select: (value: string) => void;

    export: () => IPromise<void> | undefined;
    /** @deprecated we need to get rid of this thing... */
    exportSetter: (fn: () => IPromise<void> | undefined) => void;
}

function MetricsGridDataModelsFactory(
    $rootScope: DashboardRootScope,
    $q: angular.IQService,
    MetricsFunnelNodeService: IMetricsFunnelNodeService,
) {
    class MetricsTotalGridData {
        data: IMetricsPageGridDataRow[] | null = null;
        funnel: MetricsFunnel | null = null;
        actions: {
            onError: (error: Error) => void;
        };

        constructor(params: {
            funnel?: MetricsFunnel | null;
            total: IMetricsPageGridDataRow[] | null;
            actions: {
                onError: (error: Error) => void;
            };
        }) {
            this.data = params.total;
            this.funnel = params.funnel ?? null;
            this.actions = params.actions;
            if (!this.funnel) return;
            void this.init();
        }

        protected fetchTotal() {
            if (!this.funnel) return $q.resolve(undefined);
            const filters = this.funnel.node.getFiltering();
            if (_.isEmpty(filters)) return $q.resolve(undefined);
            return Auth.getOrganization().then(organizationId => {
                if (organizationId.startsWith('sportsdirect') || !this.funnel) return $q.resolve(undefined);

                const query = MetricsFunnelNodeService.toQuery({
                    node: this.funnel.node,
                    query: $rootScope.query ?? {},
                    metricsFiltering: filters,
                });

                return MetricsFunnelNodeService.fetch(this.funnel.node, query);
            });
        }

        protected init() {
            return this.fetchTotal()
                .then(response => {
                    this.data = response?.total ?? [];
                })
                .catch(error => {
                    if (error instanceof Error) return this.actions.onError(error);
                    throw error;
                });
        }

        getTotal() {
            return _.cloneDeep(this.data);
        }
    }

    class MetricsGridDataModel {
        funnel: MetricsFunnel;
        data: MetricsGridData | null = null;
        total: MetricsTotalGridData | null = null;
        organization: string;
        actions: {
            onError: (error: Error) => void;
        };

        constructor(params: {
            funnel: MetricsFunnel;
            organization: string;
            data?: IMetricsFunnelRowData | null;
            actions: {
                onError: (error: Error) => void;
            };
        }) {
            this.funnel = params.funnel;
            this.organization = params.organization;
            this.actions = params.actions;
            void this.init(params.data ?? null);
        }

        private fetchFilteredData() {
            const filters = this.funnel.node.getFiltering();
            if (_.isEmpty(filters) || this.organization.startsWith('sportsdirect')) return $q.resolve(undefined);
            const query = MetricsFunnelNodeService.toQuery({
                node: this.funnel.node,
                query: $rootScope.query ?? {},
                metricsFiltering: filters,
            });
            return MetricsFunnelNodeService.fetch(this.funnel.node, query);
        }

        private fetchAllData(data?: IMetricsFunnelRowData | null): IPromise<IMetricsFunnelRowData> {
            if (data) return $q.resolve(data);
            const query = MetricsFunnelNodeService.toQuery({
                node: this.funnel.node,
                query: $rootScope.query ?? {},
            });
            return MetricsFunnelNodeService.fetch(this.funnel.node, query);
        }

        private init(data?: IMetricsFunnelRowData | null) {
            return $q
                .all([this.fetchAllData(data), this.fetchFilteredData()])
                .then(response => {
                    const [allData, filteredData] = response;
                    const filteredTotalData = filteredData?.total ?? [];
                    this.data = new MetricsGridData(allData);
                    this.total = new MetricsTotalGridData({
                        total: filteredTotalData.length > 0 ? filteredTotalData : this.data.getTotal(),
                        actions: this.actions,
                    });
                })
                .catch((error: Error) => {
                    this.data = new MetricsGridData();
                    this.actions.onError(error);
                });
        }

        updateTotal() {
            const filters = this.funnel.node.getFiltering();
            this.total = new MetricsTotalGridData({
                actions: this.actions,
                ...(_.isEmpty(filters) || this.organization.startsWith('sportsdirect')
                    ? { total: this.data?.getTotal() ?? [] }
                    : {
                          funnel: this.funnel,
                          total: [{ value0: 'Loading Total ...', property0: 'Loading Total ...' }],
                      }),
            });
        }
    }
    return {
        MetricsGridDataModel,
        MetricsTotalGridData,
    };
}
type IMetricsGridDataModel = InstanceType<ReturnType<typeof MetricsGridDataModelsFactory>['MetricsGridDataModel']>;

export const MetricsFunnelNodeViewDirectiveInstance = () => [
    '$rootScope',
    '$timeout',
    '$q',
    'MetricsFunnelNodeService',
    'MetricsFunnelNodeGridViewModel',
    'MetricsKPIsService',
    function MetricsFunnelNodeViewDirective(
        $rootScope: DashboardRootScope,
        $timeout: angular.ITimeoutService,
        $q: angular.IQService,
        MetricsFunnelNodeService: IMetricsFunnelNodeService,
        MetricsFunnelNodeGridViewModel: IMetricsFunnelNodeGridViewModelFactory,
        MetricsKPIsService: IMetricsKPIsService,
    ): angular.IDirective<IMetricsFunnelNodeViewDirectiveScope> {
        const { MetricsGridDataModel } = MetricsGridDataModelsFactory($rootScope, $q, MetricsFunnelNodeService);
        return {
            restrict: 'E',
            scope: {
                user: '=',
                tabName: '=',
                model: '=',
                exportSetter: '=',
                organization: '=',
                experiments: '=',
            },
            replace: true,
            template: `
                <article class="metrics-funnel-node">
                    <div class="ag-42 grid grid-new ag-theme-alpine"
                        ng-if="grid.options"
                        ag-grid="grid.options"
                        ng-class="{'multi-selection-property': drilldownModel.enabled}"
                    ></div>
                    <button class="reset-grid-button" ng-class="{ 'hidden': !enableClearFilters }" ng-click="clearFilters()">
                        <span>Clear Filters</span>
                    </button>
                </article>
            `,
            link: function MetricsFunnelNodeViewDirectiveLink(scope, element) {
                scope.grid = MetricsFunnelNodeGridViewModel(scope);

                scope.drilldownModel = {
                    enabled: false,
                    active: false,
                    selectedValuesByProperty: {},
                };

                const selectMultipleProperties = () => {
                    if (!scope.drilldownModel.active) {
                        const values = scope.drilldownModel.selectedValuesByProperty;
                        scope.drilldownModel.selectedValuesByProperty = {};
                        if (!_.isEmpty(values)) scope.grid.options.api?.redrawRows();
                        return;
                    }
                    if (_.isEmpty(scope.drilldownModel.selectedValuesByProperty)) return;
                    const rowDrilldownValues = _.compact(Object.values(scope.drilldownModel.selectedValuesByProperty));
                    if (_.isEmpty(rowDrilldownValues)) return;
                    const values = rowDrilldownValues.reduce<Record<string, Set<string | number>>>((acc, rowValues) => {
                        for (const [key, value] of Object.entries(rowValues)) {
                            acc[key] ??= new Set();
                            value.forEach(v => acc[key]?.add(v));
                        }
                        return acc;
                    }, {});
                    const drilldown = Object.entries(values).reduce<Record<string, (string | number)[]>>(
                        (acc, [key, value]) => {
                            acc[key] = Array.from(value);
                            return acc;
                        },
                        {},
                    );

                    scope.model.drilldown(drilldown);
                    scope.drilldownModel.selectedValuesByProperty = {};
                };

                const keyboardObserver = new KeyboardObserverAngular(scope, window);
                keyboardObserver.onKeyPress((event: KeyboardEvent) => {
                    if (event.key !== 'Shift') {
                        const values = scope.drilldownModel.selectedValuesByProperty;
                        scope.drilldownModel.selectedValuesByProperty = {};
                        scope.drilldownModel.enabled = false;
                        if (!_.isEmpty(values)) scope.grid.options.api?.redrawRows();
                        return;
                    }
                    if (event.type === 'keydown') {
                        scope.drilldownModel.selectedValuesByProperty = {};
                        scope.drilldownModel.enabled = true;
                    } else {
                        scope.drilldownModel.enabled = false;
                        selectMultipleProperties();
                    }
                });

                // Watch for mouse click outside of the grid
                const mouseBodyObserver = new MouseObserverAngular(scope, window, { preventDefault: false });
                mouseBodyObserver.onMouseClick((event: MouseEvent) => {
                    if (!scope.drilldownModel.enabled) return;
                    const pinnedColumnsContainer = element.find('.ag-pinned-left-cols-container')[0];
                    if (!pinnedColumnsContainer) return;
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    const target = event.target as null | HTMLInputElement;
                    if (!pinnedColumnsContainer.contains(target)) {
                        scope.drilldownModel.selectedValuesByProperty = {};
                    }
                });

                // Watch for mouse inside grid
                if (!element[0]) throw new Error('Element not found');
                const mouseElementObserver = new MouseObserverAngular(scope, element[0]);
                mouseElementObserver.onMouseEnter(() => {
                    scope.$applyAsync(() => {
                        scope.drilldownModel.active = true;
                    });
                });
                mouseElementObserver.onMouseLeave(() => {
                    scope.$applyAsync(() => {
                        scope.drilldownModel.active = false;
                        const values = scope.drilldownModel.selectedValuesByProperty;
                        scope.drilldownModel.selectedValuesByProperty = {};
                        if (!_.isEmpty(values)) setTimeout(() => scope.grid.options.api?.redrawRows(), 0);
                    });
                });

                let timer: undefined | IPromise<undefined>;
                const initClearFilterWatcher = () => {
                    const mouseModeCB = () => {
                        if (scope.enableClearFilters) element.find('.reset-grid-button').addClass('show');
                        $timeout.cancel(timer);
                        timer = $timeout(() => void element.find('.reset-grid-button').removeClass('show'), 500);
                    };

                    element.on('mousemove', mouseModeCB);
                    return () => {
                        element.off('mousemove', mouseModeCB);
                    };
                };

                let unWatchClearFilters: () => void = () => {};
                scope.$watch('enableClearFilters', (enableClearFilters: boolean) => {
                    unWatchClearFilters();
                    if (enableClearFilters) unWatchClearFilters = initClearFilterWatcher();
                });

                const isProperty = (field: string) => /^property\d+/.test(field);
                const isItemMetric = (field: string) => field.startsWith('item_');
                const onError = (error: Error) => {
                    scope.enableClearFilters = !_.isEmpty(scope.model.node.getFiltering());
                    Analytics.logError(new Error('[metrics funnel] [refresh] error', { cause: error }));
                    scope.grid.setError(true);
                    throw error;
                };
                let watchFiltering: () => void = () => {};
                const refresh = () => {
                    watchFiltering();
                    scope.grid.setError(false);
                    scope.metricsGridDataModel = new MetricsGridDataModel({
                        funnel: scope.model,
                        organization: scope.organization,
                        actions: { onError },
                    });
                    scope.grid.options.api?.showLoadingOverlay();

                    watchFiltering = scope.$watch('model.node.filtering', () => {
                        if (!scope.metricsGridDataModel?.data) return;
                        scope.grid.setError(false);
                        scope.metricsGridDataModel.updateTotal();
                    });
                };

                const updateGridData = () => {
                    if (!scope.metricsGridDataModel) return;
                    scope.grid.updateData({
                        node: scope.model.node,
                        data: scope.metricsGridDataModel.data?.getRowsAndTotal() ?? null,
                        widths: scope.model.views.columns,
                    });
                    scope.enableClearFilters = !_.isEmpty(scope.model.node.getFiltering());
                };

                scope.$watch('metricsGridDataModel.data', () => updateGridData());
                scope.$watch('metricsGridDataModel.funnel.node', () => {
                    if (scope.metricsGridDataModel && !scope.metricsGridDataModel.data) updateGridData();
                });

                scope.$watch('metricsGridDataModel.total.data', () => {
                    if (!scope.metricsGridDataModel) return;
                    scope.grid.updateTotalRowData(scope.metricsGridDataModel.total?.getTotal() ?? []);
                });

                scope.clearFilters = () => {
                    scope.grid.options.api?.setFilterModel(null);
                    scope.enableClearFilters = false;
                };

                scope.onColumnResize = (columnsResized: Record<string, number>) => {
                    scope.model.updateGridConfigColumnWidth(columnsResized);
                };
                scope.onRowSortChange = (sortedColumns: IMetricsGridSort[]) => {
                    scope.model.updateSort(sortedColumns);
                };

                scope.onFilterChanged = _.debounce((columnFilters: IAGGridFiltering) => {
                    const metricsByField = _.keyBy(scope.model.metrics.selected, 'field');
                    // when the cellFilter is percentage, the `numberParser` picks the filter value and does this transformation FilterExpressionParser.percent(Number(text))
                    // so we need to multiply the filter value by 100 to get the correct value to save, otherwise it would change the value the user inputs
                    for (const [key, columnFilter] of Object.entries(columnFilters)) {
                        const metric = metricsByField[key];
                        if (!metric) throw new Error(`Metric ${key} not found`);
                        if (metric.cellFilter?.startsWith('percent')) {
                            if ('filter' in columnFilter && typeof columnFilter.filter === 'number') {
                                columnFilter.filter = columnFilter.filter * 100;
                            }
                            if ('condition1' in columnFilter && typeof columnFilter.condition1.filter === 'number') {
                                columnFilter.condition1.filter = columnFilter.condition1.filter * 100;
                            }
                            if ('condition2' in columnFilter && typeof columnFilter.condition2.filter === 'number') {
                                columnFilter.condition2.filter = columnFilter.condition2.filter * 100;
                            }
                        }
                    }

                    if (_.isEqual(convertToAGGridFiltering(scope.model.node.getFiltering()), columnFilters)) return;
                    scope.enableClearFilters = !_.isEmpty(columnFilters);
                    scope.model.updateGridColumnFilters(columnFilters);
                }, 500);

                scope.exportSetter(() => {
                    const query = _.cloneDeep($rootScope.query ?? {});
                    return $q.when(MetricsKPIsService.fetch(query)).then(metrics => {
                        const metricsByField = Object.fromEntries(metrics.map(x => [x.field, x]));

                        const associatedProperties = MetricsFunnelNodeService.getAssociatedProperties(scope.model.node);

                        const itemMetricProperties = metrics
                            .filter(x => isItemMetric(x.field))
                            .map(x => x.field.replace(/^item_/, 'items.'));

                        const metricDefs = scope.grid.getColumnDefs(scope.model.node, null).flatMap(x => {
                            const field = x.field;
                            if (typeof field !== 'string') return [];
                            if (isProperty(field) || field === 'item_image__left') return [];
                            const metricDef = metricsByField[field];
                            if (metricDef?._cellClass) x.cellClass = metricDef._cellClass;
                            x = _.cloneDeep(x);
                            x = deepStripAngularProperties(x);
                            delete x._cellClass;
                            delete x.cellRenderer;
                            delete x.drilldown;
                            delete x.filter;
                            delete x.columnViewName;
                            delete x.category;
                            return [x];
                        }, []);

                        query.options = {
                            ...(query.options ?? {}),
                            associatedProperties: [...associatedProperties, ...itemMetricProperties],
                            metrics: _.compact(metricDefs.map(columnDef => columnDef.field)),
                        };
                        query.export = {
                            properties: deepStripAngularProperties(scope.model.properties),
                            columnStyle: 'tabular',
                            columnDefs: metricDefs,
                        };

                        return MetricsFunnelNodeService.runExport({
                            node: scope.model.node,
                            query,
                            type: 'xlsx',
                            tabName: scope.tabName,
                            metricsByField,
                        });
                    });
                });

                scope.$watch('grid.columnSortOrder', (columnSortOrder: string[]) => {
                    scope.model.updateColumnSortOrder(columnSortOrder);
                });

                scope.$watch(
                    'model.metrics.selected',
                    (selectedMetrics: IColumnDef[]) => {
                        const gridMetrics = scope.grid.columnSortOrder;
                        const modelMetrics = selectedMetrics.map(x => x.field);
                        if (_.isEqual(gridMetrics, modelMetrics)) return;
                        scope.grid.updateColumns({
                            node: scope.model.node,
                            columns: scope.model.metrics.selected,
                            widths: scope.model.views.columns ?? {},
                        });
                    },
                    true,
                );

                scope.$watch('model.node', () => refresh());
                scope.$on(
                    '$destroy',
                    $rootScope.$on('query.refresh', () => refresh()),
                );
            },
        };
    },
];

export type MetricCellRenderer = (data: unknown) => string;
export interface IMetricsGridCellRenderers {
    image: MetricCellRenderer;
}

export interface IMetricsFunnelNodeGridViewModelOptions {
    suppressSorting?: boolean | undefined;
    suppressFiltering?: boolean | undefined;
}

export type IMetricsFunnelNodeGridViewModelFactory = AngularInjected<typeof MetricsFunnelNodeGridViewModelFactory>;
export type IMetricsFunnelNodeGridViewModel = ReturnType<IMetricsFunnelNodeGridViewModelFactory>;

export const MetricsFunnelNodeGridViewModelFactory = () => [
    'MetricsGridCellRenderers',
    'MetricsGridDefaultCellRenderer',
    function MetricsFunnelNodeGridViewModelInstance(
        MetricsGridCellRenderers: IMetricsGridCellRenderers,
        MetricsGridDefaultCellRenderer: MetricCellRenderer,
    ) {
        // make sure this matches the CSS
        const metricHeaderGroupFont = new FontWidthCalculator({
            font: '700 10px "Open Sans"',
            textTransform: 'uppercase',
            letterSpacing: 1,
        });

        const metricHeaderNameFont = new FontWidthCalculator({
            font: '400 10px "Open Sans"',
            textTransform: 'uppercase',
            letterSpacing: 1,
        });

        return (scope: IMetricsFunnelNodeViewDirectiveScope, options: IMetricsFunnelNodeGridViewModelOptions = {}) => {
            let dataColumns: IColumnDef[] = [];
            const rowsOverlay = GridOverlayMessageRendererFactory();

            const gridOptions: AgGrid.GridOptions = {
                applyColumnDefOrder: true,
                angularCompileFilters: true,
                headerHeight: GRID_HEADER_ROW_HEIGHT,
                suppressDragLeaveHidesColumns: true,
                sortingOrder: options.suppressSorting ? [null] : ['desc', 'asc', null],
                noRowsOverlayComponent: rowsOverlay.component,

                onSortChanged: event => {
                    console.log('[metrics][grid] onSortChanged:', event);
                    const columns = event.columnApi.getColumnState();
                    let columnsWithSort: { sortIndex?: null | undefined | number; sort: IMetricsGridSort }[] =
                        columns.flatMap(col => {
                            const { colId: field, sort, sortIndex } = col;
                            if (!field) return [];
                            const order = sort === 'asc' ? 1 : sort === 'desc' ? -1 : undefined;
                            if (order === undefined) return [];
                            return [{ sortIndex: sortIndex, sort: { field, order } }];
                        });
                    columnsWithSort = _.sortBy(columnsWithSort, ['sortIndex']);
                    scope.onRowSortChange(columnsWithSort.map(x => x.sort));
                    scope.$applyAsync();
                },

                onColumnResized: _.debounce((event: AgGrid.ColumnResizedEvent) => {
                    if (!event.column && !event.columns) return;
                    const columns = event.column ? [event.column] : event.columns ?? [];
                    const updated = Object.fromEntries(columns.map(c => [c.getColId(), c.getActualWidth()]));
                    console.log('[metrics][grid] onColumnResized:', updated);
                    scope.onColumnResize(updated);
                    scope.$applyAsync();
                }, 200),

                onDragStopped: () => {
                    updateColumnOrder();
                },

                onFilterChanged: event => {
                    if (event.type !== 'filterChanged') return;
                    scope.onFilterChanged(event.api.getFilterModel());
                },
            };

            const setError = (error: boolean) => {
                if (error) {
                    rowsOverlay.setError(true);
                    gridOptions.api?.hideOverlay();
                    gridOptions.api?.showNoRowsOverlay();
                } else {
                    rowsOverlay.setError(false);
                    gridOptions.api?.hideOverlay();
                }
            };

            const updateColumnOrder = () => {
                const columnDefs = gridOptions.api?.getColumnDefs() ?? [];
                const columns: AgGrid.ColDef[] = columnDefs.flatMap(x => ('children' in x ? x.children : []));
                const prev = modelFields.columnSortOrder;
                const curr = columns.flatMap(x =>
                    (_.isNil(x.pinned) || x.pinned === false) && typeof x.field === 'string' ? [x.field] : [],
                );
                if (_.isEqual(curr, prev)) return;
                modelFields.columnSortOrder = curr;
                // Preserve Order if user changes order of columns during rows update
                if (columns.length === 0) return;
                const fields = columns.map(x => x.field);
                dataColumns.sort((a, b) => fields.indexOf(a.field) - fields.indexOf(b.field));
            };

            const shouldShowImageColumn = (node: MetricsFunnelNode): boolean => {
                const property = node.property;
                return property.every(x => x.id.startsWith('items.') && x.id !== 'items.season');
            };

            const getImageColumnDef = (): null | Omit<IColumnDef, 'headerGroup'> => {
                return {
                    headerName: '',
                    field: 'item_image__left', // This is used as a column ID
                    width: GRID_DATA_ROW_HEIGHT__WITH_IMAGE,
                    cellClass: 'item-image-render',
                    lockPinned: true,
                    lockPosition: true,
                    sortable: false,
                    pinned: true,
                    autoHeight: true,
                    flex: 0,
                    cellRenderer: (params: { data: Record<string, string | number> }) => {
                        return isTotalRow(params.data) ? '' : MetricsGridCellRenderers.image(params.data);
                    },
                };
            };

            const getRowCalendarSortTimestamp = (node: AgGrid.RowNode): string | null => {
                // Using assertions here for performance reasons...
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
                const timestamp: unknown = node.data.calendar__timerange_start;
                return typeof timestamp === 'string' ? timestamp : null;
            };

            const CalendarPropertyComparator = (
                sort: undefined | null | { nulls?: 'first' | 'last' | undefined | null },
            ): NonNullable<IColumnDef['comparator']> => {
                const nullRank = sort?.nulls === 'first' ? 1 : sort?.nulls === 'last' ? -1 : 0;
                return (_, __, nodeA, nodeB): number => {
                    const timerangeA = getRowCalendarSortTimestamp(nodeA);
                    if (timerangeA === null) return nullRank;
                    const timerangeB = getRowCalendarSortTimestamp(nodeB);
                    if (timerangeB === null) return nullRank;
                    return timerangeA === timerangeB ? 0 : timerangeA < timerangeB ? -1 : 1;
                };
            };

            const calendarPropertyComparator = CalendarPropertyComparator(undefined);
            const getGroupByColumnDef = (node: MetricsFunnelNode): AgGrid.ColDef[] => {
                return _.compact(
                    node.property
                        .map((_, i) => `property${i}`)
                        .map((propertyN, index) => {
                            const property = node.property[index];
                            if (!property) return null;
                            return {
                                field: propertyN,
                                cellClass: (params: { rowIndex: number; data?: Record<string, string | number> }) => {
                                    const propertyId = scope.model.node.property[index]?.id;
                                    const isSelected = (() => {
                                        if (!propertyId || !('data' in params) || !(propertyN in params.data))
                                            return false;
                                        const value = params.data[propertyN];
                                        if (_.isNil(value)) return false;
                                        return Boolean(scope.drilldownModel.selectedValuesByProperty[params.rowIndex]);
                                    })();

                                    const rowValue = isTotalRow(params.data) ? 'total' : '';
                                    const selectedClass = isSelected ? 'selected' : '';
                                    return `pinned-property ${rowValue} ${selectedClass}`;
                                },
                                filter: options.suppressFiltering === true ? false : 'agTextColumnFilter',
                                sortable: options.suppressSorting === true ? false : true,
                                headerName: property.label,
                                lockPinned: true,
                                lockPosition: true,
                                cellRenderer: (params: { data: Record<string, string | number> }) => {
                                    return isTotalRow(params.data) ? 'Total' : purifyText(params.data[propertyN]);
                                },
                                // it does not drilldown in Ads Page
                                onCellClicked: (params: {
                                    rowIndex: number;
                                    data: Record<string, string | number>;
                                }) => {
                                    if (isTotalRow(params.data)) return;
                                    const drilldown = scope.model.node.property.reduce<
                                        Record<string, (string | number)[]>
                                    >((acc, property, index) => {
                                        const value = params.data[`property${index}`];
                                        if (value === undefined) return acc;
                                        return { ...acc, [property.id]: [value] };
                                    }, {});

                                    if (_.isEmpty(drilldown)) return;
                                    if (scope.drilldownModel.enabled && gridOptions.api) {
                                        const { rowIndex } = params;
                                        scope.drilldownModel.selectedValuesByProperty[rowIndex] = scope.drilldownModel
                                            .selectedValuesByProperty[rowIndex]
                                            ? undefined
                                            : drilldown;
                                        if ('node' in params) {
                                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                                            const node = params.node as RowNode;
                                            gridOptions.api.redrawRows({ rowNodes: [node] });
                                        }
                                        return;
                                    } else {
                                        scope.$applyAsync(() => {
                                            scope.model.drilldown(drilldown);
                                        });
                                    }
                                },
                            };
                        }),
                );
            };

            const getDimensionColumnDefs = (node: MetricsFunnelNode): AgGrid.ColDef[] => {
                const groupByColumn = getGroupByColumnDef(node);
                // let { columns, width } = MetricsFunnelNodeGridViewModelAssociatedColumns(node, scope.organization);
                // width = typeof width === 'number' ? width : 200;
                const width = 200;
                const columns = [];

                const result = groupByColumn.map(g => ({
                    ...g,
                    width,
                }));

                return [...result, ...columns].map(column => {
                    const propertyId = (() => {
                        if (column.field.startsWith('property')) {
                            const indexOfProperty = column.field.split('property')[1];
                            return node.property[indexOfProperty].id;
                        }

                        return column.field;
                    })();
                    const comparator = propertyId.startsWith('calendar.')
                        ? { comparator: calendarPropertyComparator }
                        : {};
                    return {
                        wrapText: true,
                        flex: 1,
                        ...comparator,
                        ...column,
                    };
                });
            };

            const mergeColumnDefs = (node: MetricsFunnelNode, metrics: IColumnDef[]): AgGrid.ColDef[] => {
                const imageColumn = shouldShowImageColumn(node) ? getImageColumnDef() : null;
                const rows = getDimensionColumnDefs(node);
                const pinnedColumns: AgGrid.ColDef[] = [...rows, ...(imageColumn ? [imageColumn] : [])].map(column => {
                    return {
                        pinned: 'left',
                        suppressMovable: true,
                        lockPinned: true,
                        lockPosition: true,
                        ...column,
                    };
                });
                metrics = metrics.map(metric => ({ ...metric }));
                return pinnedColumns.concat(metrics);
            };

            const getColumnDefs = (node: MetricsFunnelNode, columns: IColumnDef[] | null): IColumnDef[] => {
                columns ??= _.cloneDeep(dataColumns);
                columns = mergeColumnDefs(node, columns);

                columns.forEach((columnDef: IColumnDef) => {
                    // NOTE: messing with this stuff can remove the ability to filter on the property column, so refactor with caution...
                    if (columnDef.field) columnDef.headerName ??= titleize(columnDef.field);
                    columnDef.cellRenderer ??= MetricsGridDefaultCellRenderer;
                    columnDef.columnGroupShow = 'open';
                    columnDef.resizable ??= true;
                    columnDef.sortable ??= options.suppressSorting ? false : true;
                    columnDef.filter ??= (() => {
                        if (options.suppressFiltering) return false;
                        if (!columnDef.cellFilter) return false;
                        const cellFilterValue = columnDef.cellFilter.split(':')[0];
                        return cellFilterValue && ['number', 'percent', 'money'].includes(cellFilterValue)
                            ? 'agNumberColumnFilter'
                            : false;
                    })();

                    if (columnDef.filter === 'agNumberColumnFilter') {
                        const filterType = (() => {
                            const cellFilterValue = columnDef.cellFilter?.split(':')[0];
                            if (cellFilterValue) {
                                return ['number', 'percent', 'money'].find(filterType =>
                                    filterType.includes(cellFilterValue),
                                );
                            }
                        })();

                        columnDef.filterParams = {
                            allowedCharPattern: '\\d\\-\\%\\.',
                            filterOptions: [
                                'equals',
                                'notEqual',
                                'lessThan',
                                'lessThanOrEqual',
                                'greaterThan',
                                'greaterThanOrEqual',
                                // remove until we support $in operator and test it
                                //'inRange',
                            ],
                            numberParser: (text: string | null | number) => {
                                if (text !== null && filterType) {
                                    switch (filterType) {
                                        case 'number':
                                            text = FilterExpressionParser.number(Number(text))?.value ?? text;
                                            break;
                                        case 'money':
                                            text = FilterExpressionParser.money(text)?.value ?? text;
                                            break;
                                        case 'percent':
                                            text = FilterExpressionParser.percent(Number(text))?.value ?? text;
                                            break;
                                    }
                                }

                                return text;
                            },
                        };
                    }
                });

                return columns;
            };

            // Helper to append one or more classes to an existing cell class, that works iteratively.
            const CellClassExtender = () => {
                const __originalCellClass__ = Symbol();

                const isWrappedCellClassFn = (
                    x: unknown,
                ): x is CallableFunction & { [__originalCellClass__]: AgGrid.ColDef['cellClass'] } => {
                    return _.isFunction(x) && __originalCellClass__ in x;
                };

                const normalizeCellClass = (cellClass: AgGrid.ColDef['cellClass']) => {
                    const getter = _.isFunction(cellClass) ? cellClass : () => cellClass;
                    return (params: AgGrid.CellClassParams) => {
                        const result = getter(params);
                        return Array.isArray(result)
                            ? result
                            : typeof result === 'string' && result.length > 0
                            ? [result]
                            : [];
                    };
                };

                return (cellClass: AgGrid.ColDef['cellClass'], extendedCellClass: AgGrid.ColDef['cellClass']) => {
                    const originalCellClass = isWrappedCellClassFn(cellClass)
                        ? cellClass[__originalCellClass__]
                        : cellClass;
                    // if there's nothing to extend, then we exit early as an optimization...
                    if (extendedCellClass === undefined) return originalCellClass;
                    if (Array.isArray(extendedCellClass) && extendedCellClass.length === 0) return originalCellClass;
                    const originalCellClassFn = normalizeCellClass(originalCellClass);
                    const extendedCellClassFn = normalizeCellClass(extendedCellClass);
                    const wrappedCellClassFn = (params: AgGrid.CellClassParams) => {
                        const original = originalCellClassFn(params);
                        const extended = extendedCellClassFn(params);
                        return [...original, ...extended];
                    };
                    Object.defineProperty(wrappedCellClassFn, __originalCellClass__, { value: originalCellClass });
                    return wrappedCellClassFn;
                };
            };

            const updateCellValueGroupEndCellClass = (() => {
                const extendCellClass = CellClassExtender();
                return (columns: IColumnDef[]) => {
                    return columns.map((column, index, array) => {
                        const nextColumn = array[index + 1];
                        const prevColumn = array[index - 1];
                        const nextHeaderGroup = nextColumn?.headerGroup;
                        const prevHeaderGroup = prevColumn?.headerGroup;
                        const currHeaderGroup = column.headerGroup;
                        const isEnd = nextHeaderGroup !== currHeaderGroup;
                        const isStart = prevHeaderGroup !== currHeaderGroup;
                        const cellClass = extendCellClass(column.cellClass, [
                            ...(isStart ? ['column-group-start'] : []),
                            ...(isEnd ? ['column-group-end'] : []),
                        ]);
                        return { ...column, cellClass };
                    });
                };
            })();

            const updatePinnedNameColumn = (names: string[]): void => {
                const columns = gridOptions.api?.getColumnDefs() ?? [];
                const pinnedGroup = columns.find(x => 'groupId' in x && x.groupId === ' ');
                if (!pinnedGroup || !('children' in pinnedGroup)) return;
                const children: AgGrid.ColDef[] = pinnedGroup.children.filter(x => 'field' in x);
                const pinnedColumns: AgGrid.ColDef[] = [];
                let indexf = 0;
                children.forEach((child, index) => {
                    if ('field' in child && child.field.startsWith('property')) {
                        const propertyIndex = child.field.split('property')[1];
                        if (propertyIndex === undefined) return;
                        const name = names[Number(propertyIndex)];
                        child.headerName = name;
                        indexf++;
                    }

                    if (indexf <= names.length) {
                        pinnedColumns.push(child);
                    }
                });
                pinnedGroup.children = pinnedColumns;
                gridOptions.api?.setColumnDefs(columns);
            };

            const updateColumns = (params: {
                node: MetricsFunnelNode;
                columns: IColumnDef[];
                widths?: IMetricsGridConfigViewsColumns;
            }) => {
                const { node, columns, widths = {} } = params;
                dataColumns = _.cloneDeep(columns);
                const hasImage = shouldShowImageColumn(node);
                gridOptions.api?.setGetRowHeight((params: { data: Record<string, unknown> }) => {
                    return isTotalRow(params.data)
                        ? GRID_HEADER_ROW_HEIGHT
                        : hasImage
                        ? GRID_DATA_ROW_HEIGHT__WITH_IMAGE
                        : GRID_DATA_ROW_HEIGHT;
                });
                const columnDefs = updateCellValueGroupEndCellClass(getColumnDefs(node, columns));
                const gridColumnDefs = gridOptions.api?.getColumnDefs() ?? [];
                const gridColumnDefsByGroupId = _.keyBy(
                    _.cloneDeep(gridColumnDefs).filter(x => 'groupId' in x),
                    'groupId',
                );
                const columnDefGroups = columnDefs.reduce<Record<string, AgGrid.ColGroupDef>>((acc, column) => {
                    column.headerGroup = column.headerGroup ?? ' ';
                    const headerName = column.headerGroup;

                    const columnDefinition: AgGrid.ColGroupDef = (() => {
                        const columnDef = acc[headerName];
                        if (columnDef) return columnDef;
                        const gridColumnDef = gridColumnDefsByGroupId[headerName];
                        if (gridColumnDef) return { ...gridColumnDef, children: [] };
                        return {
                            groupId: headerName,
                            headerName: headerName,
                            marryChildren: true,
                            headerClass: ['column-group-start', 'column-group-end'],
                            children: [],
                        };
                    })();

                    const existingGridChild = (() => {
                        if (!gridColumnDefsByGroupId[headerName]) return;
                        const columnDef = gridColumnDefsByGroupId[headerName];
                        return columnDef && 'children' in columnDef
                            ? columnDef.children.find(child => 'field' in child && child.field === column.field)
                            : undefined;
                    })();

                    if (existingGridChild) {
                        columnDefinition.children.push(existingGridChild);
                    } else {
                        const child = _.omit(column, 'headerGroup');
                        const cellClass = child.cellClass ?? [];
                        const headerClass = child.cellClass ?? [];
                        columnDefinition.children.push({ ...child, cellClass, headerClass, groupId: headerName });
                    }

                    const sort = node.getSort();
                    if (sort && 'children' in columnDefinition) {
                        columnDefinition.children.forEach(c => {
                            if ('field' in c) {
                                const field = c.field;
                                const columnSortIndex = sort.findIndex(s => s.field === field);
                                if (columnSortIndex === -1) {
                                    c.sort = null;
                                } else {
                                    const columnSort = sort[columnSortIndex] ?? { order: 1 };
                                    c.sort = columnSort.order === 1 ? 'asc' : 'desc';
                                    c.sortIndex = columnSortIndex;
                                }
                            }
                        });
                    }

                    acc[headerName] = columnDefinition;
                    return acc;
                }, {});

                const columnDefsResult = Object.values(columnDefGroups);
                columnDefsResult.forEach(c => fixColumnWidths(c, gridColumnDefs, widths));

                gridOptions.api?.setColumnDefs(_.cloneDeep(columnDefsResult));
                updateColumnOrder();
            };

            const fixColumnWidths = (
                columnDef: AgGrid.ColGroupDef,
                gridColumnDefs: (AgGrid.ColDef | AgGrid.ColGroupDef)[],
                widths: IMetricsGridConfigViewsColumns,
            ) => {
                if (columnDef.children.length === 0 || isSameColumnDef(columnDef, gridColumnDefs)) return;

                let childTotalWidth = 0;

                columnDef.children.forEach((child: AgGrid.ColDef) => {
                    const text = (child.headerName ?? '').trim();
                    child.width = child.width ?? Math.ceil(metricHeaderNameFont.getPixelWidth(text)) + 16 + 2 * 22;
                    child.width = Math.max(120, child.width);
                    childTotalWidth += child.width;
                });

                const headerGroupText = (columnDef.headerName ?? '').trim();
                const headerGroupTextWidth = Math.ceil(metricHeaderGroupFont.getPixelWidth(headerGroupText)) + 36;
                if (headerGroupTextWidth > childTotalWidth) {
                    const diff = headerGroupTextWidth - childTotalWidth;
                    const toAdd = diff / columnDef.children.length;
                    columnDef.children.forEach((c: AgGrid.ColDef) => (c.width = (c.width ?? 0) + toAdd));
                }

                columnDef.children.forEach((c: AgGrid.ColDef) => {
                    const widthValue = !_.isNil(c.field) ? widths[c.field] : null;
                    if (typeof widthValue === 'number') c.width = widthValue;
                });
            };

            const isSameColumnDef = (
                columnGroupDef: AgGrid.ColGroupDef,
                gridColumnDefs: (AgGrid.ColDef | AgGrid.ColGroupDef)[] | undefined,
            ) => {
                const colId = columnGroupDef.groupId;

                gridColumnDefs = gridColumnDefs ?? [];
                const gridColumn = gridColumnDefs.find(col => 'groupId' in col && col.groupId === colId);

                if (!gridColumn) {
                    return false;
                }

                if ('children' in gridColumn) {
                    const columnGroupDefChildren = columnGroupDef.children;
                    const gridColumnChildren = gridColumn.children;

                    if (columnGroupDefChildren.length !== gridColumnChildren.length) {
                        return false;
                    }

                    return columnGroupDefChildren.every(child => {
                        return (
                            gridColumnChildren.findIndex(
                                gridChild =>
                                    'field' in gridChild && 'field' in child && gridChild.field === child.field,
                            ) > -1
                        );
                    });
                }

                return false;
            };

            const updateTotalRowData = (data: IMetricsPageGridDataRow[] | null) => {
                const totalRow = gridOptions.api?.getPinnedTopRow(0);
                const totalRowId = totalRow?.id;

                if (!totalRowId) return;
                gridOptions.api?.setPinnedTopRowData(data ?? []);
            };

            const updateData = (params: {
                node: MetricsFunnelNode;
                data: IMetricsFunnelRowData | null;
                widths: IMetricsGridConfigViewsColumns;
            }) => {
                const { node, data, widths = {} } = params;
                const { rows, total } = { ...data };
                const filters = convertToAGGridFiltering(node.getFiltering());
                if (_.isNil(rows)) {
                    gridOptions.api?.setPinnedTopRowData([]);
                    gridOptions.api?.setRowData([]);
                    gridOptions.api?.setFilterModel(filters);
                    gridOptions.api?.showLoadingOverlay();
                    updateColumns({ node, columns: dataColumns, widths });
                    setTimeout(() => updatePinnedNameColumn(node.property.map(p => p.label)), 0);
                } else {
                    gridOptions.api?.setPinnedTopRowData(total ?? []);
                    updateColumns({ node, columns: dataColumns, widths });
                    gridOptions.api?.setRowData(rows);
                    gridOptions.api?.setFilterModel(filters);
                    gridOptions.api?.hideOverlay();
                    if (rows.length === 0) {
                        gridOptions.api?.showNoRowsOverlay();
                    }
                }
            };

            const resetColumnDefs = () => gridOptions.api?.setColumnDefs([]);

            const columnSortOrder: string[] = []; // NOTE: list of metric ids
            const modelFields = {
                options: gridOptions,
                updateData,
                resetColumnDefs,
                getColumnDefs,
                updateColumns,
                updateTotalRowData,
                columnSortOrder,
                setError,
            };

            return modelFields;
        };
    },
];
