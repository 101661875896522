import _ from 'lodash'
import moment from 'moment-timezone'
import Utils from '../../lib/utils'
import * as Analytics from '../../lib/analytics'
import * as HierarchyLib from '../../lib/config-hierarchy'
import { createSortable } from '../../lib/dom/sortable'
import { DropObserver } from '../../lib/dom/drop-observer'
import { downloadFromJSON } from '../../lib/dom/download'
import { QueryServiceExport } from '../../modules/services/query-service-export'
import { createCalendarModelService } from '../../modules/datepicker/datepicker'
import { SmartGroupFilterDescriptors, SmartGroupsPopupModel } from '../../modules/smart-groups/smart-groups.service'
import { CurrenciesService } from '../../modules/currency/currency.service'
import SchedulingCommonModule from './scheduling-controller-common'
import './scheduling-controller-common-models'

###*
@typedef {import('./components/selection-pebble').SelectionPebbleModel} SelectionPebbleModel
@typedef {import('../../lib/config-hierarchy').IPropertyDefinition} IPropertyDefinition
@typedef {import('../../modules/currency/currency.service').ICurrency} ICurrency
@typedef {import('../../lib/types').IConfigObj} IConfigObj
@typedef {import('../../lib/types').IQueryFilters} IQueryFilters
@typedef {import('../../lib/types').IQueryTableFilter} IQueryTableFilter
@typedef {import('./scheduling-controller.module').ISchedulingHierarchy} ISchedulingHierarchy
@typedef {{
    report: {
        invalidFields: Record<string, unknown>;
        flags: {
            isEmpty?: boolean;
            isInvalid?: boolean;
            hasUnsavedChanges?: boolean;
        }
    };
}} IReportingState
###
module = angular.module(SchedulingCommonModule.name)
export default module

module.config ($routeProvider, ROUTES, CONFIG) ->
    routes =
        reports:   _.extend {}, ROUTES.reportingReports,   _.pick(CONFIG.routes?.reportingReports,   'label', 'url')
        schedules: _.extend {}, ROUTES.reportingSchedules, _.pick(CONFIG.routes?.reportingSchedules, 'label', 'url')
    Object.keys(routes).forEach (k) -> $routeProvider.when(routes[k].url, routes[k])
    return


module.service 'ReportingCalendarModel', [() ->
    return createCalendarModelService()
]

module.controller 'ReportingReportsController', [
    '$q', '$scope', 'ReportingState', 'ReportingCalendarModel',
    ($q, $scope, ReportingState, ReportingCalendarModel) ->
        $scope.reportTemplatesModel = null
        loadingPromise = $q.all([ReportingState.fetch(), ReportingCalendarModel.init()])
        loadingPromise.then ([models]) ->
            $scope.reportTemplatesModel = models.templates
            $scope.schedules            = models.schedules
        return
]


module.service 'ReportSmartGroups', ->
    popup: new SmartGroupsPopupModel()


module.controller 'ReportingSchedulesController', [
    '$q', '$routeParams', '$location', '$scope', 'ReportingModels', 'ReportingCalendarModel',
    ($q, $routeParams, $location, $scope, ReportingModels, ReportingCalendarModel) ->
        $scope.schedules = null
        loadingPromise = $q.all([ReportingModels.Create(), ReportingCalendarModel.init()])
        loadingPromise.then ([{schedules}]) ->
            id = $routeParams.id
            selectedSchedule = do ->
                return if schedules.available.length is 0
                return schedules.viewState.active if not id
                selected = schedules.available.find((schedule) -> schedule.id is id)
                return selected if selected
                return schedules.viewState.active
            if selectedSchedule
                if not id or id isnt selectedSchedule.id
                    $location.path(schedules.getScheduleLink(selectedSchedule), false)
                if selectedSchedule?.id isnt schedules.viewState.active
                    schedules.select(selectedSchedule)
            $scope.schedules = schedules
            return
        return
]


module.directive 'reportTemplateList', ->
    restrict: "E"
    scope:
        reportTemplates: "=model"
    template: \
    """
    <article class="list-container report-templates list-container-report-templates" ng-if="!reportTemplates.selected">
        <ul>
            <li ng-repeat="template in reportTemplates.available" ng-click="reportTemplates.select(template)">
                <h1 class="title">{{ template.label }}</h1>
                <p class="description">{{ template.description }}</p>
            </li>
        </ul>
    </article>
    """


module.directive 'viewReportingReports', ['ReportingState', 'ReportSmartGroups', (ReportingState, ReportSmartGroups) ->
    restrict: 'E'
    scope:
        model: '='
        schedules: '='
    replace: true
    template: \
    """
    <article
        class="view view-reporting view-reporting-list view-reporting-reports"
        ng-class="{ invalid: ReportingState.report.flags.isInvalid, unsaved: ReportingState.report.flags.hasUnsavedChanges}">
            <div class="loadable" ng-class="{loading: !model}"></div>
            <aside ng-class={'has-selected':model.selected}>
                <article class="report-select">
                    <report-template-list model="model"></report-template-list>
                    <report-list model="model" schedules="schedules" ng-if="model.selected"></report-list>
                </article>
            </aside>
            <main ng-show="model.selected.reports.viewState.active">
                <view-reporting-reports-header ng-if="model" model="model"></view-reporting-reports-header>
                <main>
                    <report-editor model="model"></report-editor>
                </main>
            </main>
            <article class="smart-groups-filter-container">
                <segments model="ReportSmartGroups.popup"></segments>
            </article>
    </article>
    """
    link: (scope) ->
        scope.ReportSmartGroups = ReportSmartGroups
        scope.ReportingState = ReportingState

        scope.$watch 'model.selected.reports.hasUnsavedChanges()', (unsavedChanges) ->
            ReportingState.report.flags.hasUnsavedChanges = unsavedChanges

        scope.$watch 'model.selected.reports.viewState.selected', () ->
            ReportingState.report.flags.isInvalid = false
            ReportingState.report.invalidFields = {}
]

module.directive 'reportList', ['ReportsModel', (ReportsModel) ->
    restrict: "E"
    scope:
        reportTemplates: "=model"
        schedules: "="
    replace: true
    template: \
    """
    <article class="list-container reports list-container-reports" drag-and-drop-zone ng-class="{'has-templates':reportTemplates.available.length > 1 }">
        <article class="list-action list-action-back report-template" ng-if="reportTemplates.available.length > 1" ng-click="reportTemplates.select(null)">
            <i class="icon-left-open-big"></i>
            <h1>{{ reportTemplates.selected.label }}</h1>
        </article>

        <article class="list-action list-action-new list-action-create report-create"
            ng-if="!reportTemplates.selected.reports.isInCreateMode()">
            <div class="ui-button" ng-click="createReport()">
                <i class="icon-plus-circled"></i>
                <span>Create Report</span>
            </div>
        </article>

        <article class="list-action list-action-create report-create"
            ng-if="reportTemplates.selected.reports.isInCreateMode() && reportTemplates.selected.reports.available.length > 0"
            ng-click="reportTemplates.selected.reports.cancel()">
            <div class="list-action-button">
                <i class="icon-cancel-circled"></i>
                <h1>Cancel</h1>
            </div>
        </article>

        <article class="list-action list-action-search"
            ng-if="reportTemplates.selected.reports.viewState.available.length > 1 && !reportTemplates.selected.reports.isInCreateMode()"
            ng-class="{filtered:reportFilter.label}">
            <i class="icon-clear-filter icon-cancel-circled" ng-click="reportFilter.label = ''" ng-show="reportFilter.label"></i>
            <input ng-model="reportFilter.label" type="text" placeholder="Filter reports..." />
            <i class="icon-search"></i>
        </article>

        <ul class="models sortable-ui">
            <li class="item item-new report report-new selected"
                ng-class="{'empty': reportTemplates.selected.reports.available.length === 0}"
                ng-if="reportTemplates.selected.reports.isInCreateMode()">
                <h1>{{ reportTemplates.selected.reports.viewState.active.label }}</h1>
                <p class="description" ng-show="reportTemplates.selected.reports.viewState.active.description">{{ reportTemplates.selected.reports.viewState.active.description }}</p>
            </li>

            <li ng-repeat="report in reportTemplates.selected.reports.viewState.available | fuzzyBy: 'label': reportFilter.label"
                class="item"
                ng-click="selectReport(report)"
                ng-class="{selected: report === reportTemplates.selected.reports.viewState.active}">
                    <div class="report-new-header">
                        <div class="report-new-header-label">
                            <h1>{{ report.label }}</h1>
                        </div>
                        <div
                            class="report-new-header-download"
                            hover-msg="Share report..."
                            ng-click="downloadReportObject(report)">
                            <i class="icon-share"></i>
                        </div>
                    </div>
                    <report-schedule-info-tooltip
                        ng-if="reportSchedules && reportSchedules[report.id]"
                        schedules="reportSchedules[report.id]">
                    </report-schedule-info-tooltip>
                    <h2 class="updated-at">
                        <span class="date">{{ report.updatedAt | date:'MMM dd' }}</span>
                        <span class="separator">at</span>
                        <span class="time">{{ report.updatedAt | date:'HH:mm' }}</span>
                        <i class="icon-clock"></i>
                    </h2>
                    <p class="description" ng-show="report.description">{{ report.description }}</p>
            </li>
        </ul>

        <article
            class="list-action list-action-report-import"
            ng-if="!reportTemplates.selected.reports.isInCreateMode()"
        >
            <div class="ui-button" ng-click="openReportImportPopup($event)">
                <i class="icon-publish"></i>
                <span>Import report...</span>
            </div>
        </article>

        <input type="file"
            id="report-file"
            class="file-input"
            name="report-file"
            accept=".json, .txt"
            style="display: none;"
        >
    </article>
    """
    link: (scope, element) ->

        scope.reportFilter = {label:''}


        # FIXME: watcher on this?
        scope.reportSchedules = do ->
            result = {}
            return result if scope.schedules.available.length is 0
            scope.schedules.available.forEach (schedule) ->
                schedule.data.reportId.forEach (reportId) ->
                    result[reportId] = result[reportId] or []
                    result[reportId].push({ name: schedule.label, id: schedule.id })
            return result

        scope.createReport = ->
            scope.reportTemplates.selected.reports.create()

        scope.$watch 'reportTemplates.selected.reports.viewState.available.length', (numberOfReports) ->
            scope.createReport() if not ((numberOfReports ? 0) > 0)

        dnd = scope.fillDragAndDropZoneExternalAPI(
            onFile: (file) ->
                onReportFileUploaded(file)
            onError: (error) ->
                Analytics.track(Analytics.EVENTS.USER_IMPORT_GRID_TAB_FAILED, {error})
        )

        onReportFileUploaded = (file) ->
            onError = -> alert('Sorry, but the file is invalid and cannot be imported.')

            try
                payload = ReportsModel.ValidateReportFile(file)
                Analytics.track(Analytics.EVENTS.USER_IMPORT_REPORT, {report: payload.data.report})
                scope.reportTemplates.selected.reports.import(payload)

            catch error
                Analytics.track(Analytics.EVENTS.USER_IMPORT_REPORT_FAILED, {error})
                onError()
                console.error(error)

        scope.openReportImportPopup = ($event) ->
            $event.preventDefault()
            $event.stopImmediatePropagation()
            dnd.openUploadPopup()
            return true

        cleanupSortable = do ->
            sortable = null
            onSortableEnd = (evt) ->
                dnd.enableDragAndDropZone()
                scope.reportTemplates.selected.reports.reorder(evt.oldIndex, evt.newIndex)
                scope.$apply()
            onSortableStart = () ->
                dnd.disableDragAndDropZone()
            sortableOptions =
                ghostClass: 'placeholder'
                filter: '.item-new'
                draggable: '.item'
                onStart: onSortableStart
                onEnd: onSortableEnd
            setTimeout (->
                sortableEl = element[0].querySelector('.sortable-ui')
                sortable = createSortable(sortableEl, sortableOptions)
            ), 200
            return -> sortable?.destroy()
        scope.$on('$destroy', cleanupSortable)

        scope.downloadReportObject = (report) ->
            return if not report
            reports = scope.reportTemplates.selected.reports
            reports.export(report).then ({filename, data}) -> downloadFromJSON(filename, data)

        scope.selectReport = (report) ->
            return if report.id is scope.reportTemplates.selected.reports.viewState.active.id
            scope.reportTemplates.selected.reports.select(report)
]

module.directive 'reportScheduleInfoTooltip', ['$location', ($location) ->
    restrict: 'E'
    scope:
        schedules: "="
    replace: true
    template: \
    """
    <div class="report-schedule">
        <div
            ng-mouseover="showTooltip()"
            ng-mouseleave="hideTooltip()"
            class="schedule-tooltip-wrapper">
            <div class="schedule-label">Scheduled</div>
            <div ng-if="enableTooltip" class="schedule-tooltip">
                <div class="schedule-tooltip-header">Used in Schedules:</div>
                <div ng-repeat="schedule in schedules" class="schedule-tooltip-series">
                    <span class="schedule-tooltip-series-label" ng-click="navigateToSchedule(schedule)">- {{ schedule.name }}</span>
                </div>
            </div>
        </div>
    </div>
    """
    link: (scope) ->
        scope.enableTooltip = false
        scope.showTooltip = -> scope.enableTooltip = true
        scope.hideTooltip = -> scope.enableTooltip = false
        scope.navigateToSchedule = (schedule) ->
            $location.path('/reporting/schedules/' + schedule.id)
]

module.directive 'viewReportingReportsHeader', ['promiseTracker', 'CONFIG', 'ReportingState',
###*
@typedef {{
    available: unknown[];
    run: () => ({report: unknown, promise: angular.IPromise<{id: string, type: string}>});
    cancel: () => void;
    copy: () => void;
    delete: () => void;
    reset: () => void;
    save: () => void;
}} IReports'
@param {angular.promisetracker.PromiseTrackerService} promiseTracker
@param {IConfigObj} CONFIG
@param {IReportingState} ReportingState
@returns {angular.IDirective<angular.IScope & {
    model?: {
        selected?: {reports?: IReports}
    };
    reports?: IReports;
    errorMessage: string;
    onSaveOrRunHover: () => void;
    runReportPromiseTracker: angular.promisetracker.PromiseTracker;
    actions: {
        save: () => void;
        reset: () => void;
        cancel: () => void;
        copy: () => void;
        delete: () => void;
        run: () => void;
    };
}>}
###
(promiseTracker, CONFIG, ReportingState) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <header class="view-reporting-list-header view-reporting-reports-header">
        <section class="actions">
            <button class="button-run" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" promise-tracker="runReportPromiseTracker" ng-click="actions.run()">Run</button>
            <button class="button-save" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" ng-click="actions.save()">Save</button>
            <button class="button-cancel button-bare" ng-click="actions.cancel()" ng-if="reports.isInCreateMode() && reports.available.length > 0">Cancel</button>
            <button class="button-copy" ng-click="actions.copy()" ng-if="!reports.isInCreateMode()">Save As...</button>
            <button class="button-reset button-bare" ng-click="actions.reset()" ng-if="!reports.isInCreateMode()">Reset</button>
            <button class="button-delete button-bare" ng-click="actions.delete()" ng-if="!reports.isInCreateMode()">Delete</button>
        </section>
    </header>
    """
    link: (scope) ->
        scope.runReportPromiseTracker = promiseTracker()

        scope.$watch 'model.selected.reports.available.length', (numberOfReports) ->
            ReportingState.report.flags.isEmpty = not ((numberOfReports ? 0) > 0)
            scope.reports = scope.model?.selected?.reports

        scope.errorMessage = ""
        scope.onSaveOrRunHover = ->
            scope.errorMessage = do ->
                return "" if not ReportingState?.report?.flags?.isInvalid
                invalidFields = Object.keys(ReportingState.report.invalidFields).filter (key) -> ReportingState.report.invalidFields[key]
                return "Invalid or missing field(s): #{invalidFields.join(', ')}."
            return

        getReportFilename = (report, response) ->
            DEFAULT_LABEL = "Report Export"
            timestamp = moment().format('YYYY-MM-DD HHmmss')
            label = report?.label or DEFAULT_LABEL
            label = label.replace(/&/g, 'and')
            label = label.replace(/[^A-Z0-9\-_ ]/gi, '')
            label = label.replace(/ +/g, ' ')
            label = label.trim()
            label = DEFAULT_LABEL if label.length is 0
            label = label.slice(0, 200)
            return "42 - #{label} - #{timestamp}.#{response.type}"

        scope.actions =
            save: ->
                return if not ReportingState.report.flags.hasUnsavedChanges
                return if ReportingState.report.flags.isInvalid
                return scope.reports?.save()
            reset: ->
                return if not ReportingState.report.flags.hasUnsavedChanges
                return scope.reports?.reset()
            cancel: ->
                return if scope.reports?.available.length is 0
                return scope.reports?.cancel()
            copy: ->
                return scope.reports?.copy()
            delete: ->
                return if not window.confirm("""
                Are you sure you want to delete this report?
                This cannot be un-done.
                """)
                return scope.reports?.delete()
            run: ->
                return if ReportingState.report.flags.isInvalid
                {report, promise} = scope.reports.run()
                scope.runReportPromiseTracker.addPromise do ->
                    promise.then (response) ->
                        data = {host:CONFIG.services.query.host, data:response}
                        filename = getReportFilename(report, response)
                        QueryServiceExport.downloadAs(filename)(data)
                    .catch (error) ->
                        alert("Could not run report due to error... sorry!")
                        Analytics.logError(error)
                        return
]


module.directive 'reportInfoEditor', ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="report-info report-info-editor">
        <main>
            <label class="report-label">
                <span>Report Name</span>
                <input type="text" ng-model="reports.viewState.active.label"></input>
            </label>
            <label class="report-description">
                <span>Description</span>
                <textarea ng-model="reports.viewState.active.description"></textarea>
            </label>
        </main>
    </article>
    """
    link: (scope) ->
        scope.$watch 'model.selected.reports', (reports) ->
            scope.reports = reports


module.directive 'reportEditor', () ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="report-editor" ng-if="model.selected.reports.viewState.active">
        <report-info-editor model="model"></report-info-editor>
        <report-params-editor model="model"></report-params-editor>
    </article>
    """


module.directive 'scheduleReportEditor', ->
    restrict: "E"
    scope:
        model:    "="
        schedule: "="
    replace: true
    template: \
    """
    <article class="report-viewer">
        <schedule-report-template-editor model="model" schedule="schedule"></schedule-report-template-editor>
        <report-params-viewer ng-if="model.hasReportId(model.selected.reports.viewState.active.id)" params="model.selected.reports.getActiveParams()"></report-params-viewer>
    </article>
    """


module.directive 'scheduleReportTemplateEditor', ['$location', 'ReportingState', ($location, ReportingState) ->
    restrict: "E"
    scope:
        model:    "="
        schedule: "="
    replace: true
    template: \
    """
    <article class="report-info report-info-viewer">
        <main ng-if="model.hasReports()">
            <label class="report-template" ng-class="{error:model.selectedReportIsInvalid()}">
                <span>What is the report to be scheduled?</span>
                <p class="error-message">
                The report that was previously selected was deleted. Please select a new report.
                </p>
                <schedule-report-template-select model="model"></report-template-editor>
            </label>
            <label class="report-description" ng-if="model.selected.reports.viewState.active.description">
                <span>Description</span>
                <p>{{ reports.viewState.active.description }}</p>
            </label>
        </main>
        <main ng-if="!model.hasReports()">
            <label class="report-description error">
                <span>No reports available!</span>
                <p>You must create a report before you can save this schedule.</p>
            </label>
        </main>
        <aside>
            <button class="button-delete" ng-if="showDeleteButton()" ng-click="delete()">
                <span>Remove</span>
            </button>
            <!--
            <aside ng-if="model.selected.reports.viewState.active">
            <button class="button-edit" ng-click="edit()">Edit Report</button>
            <button class="button-copy" ng-click="copy()">Copy Report</button>
            <button class="button-create" ng-click="create()">Create Report</button>
            -->
        </aside>
    </article>
    """
    link: (scope) ->
        postEdit = (report) ->
            ReportingState.models.schedules.viewState.selected.data.reportId = report.id
            $location.path('/reporting/schedules')
        scope.$watch 'model.selected.reports', (reports) ->
            scope.reports = reports
        scope.create = ->
            scope.reports.create({postEdit})
            $location.path('/reporting/reports')
        scope.edit = ->
            scope.reports.edit({postEdit})
            $location.path('/reporting/reports')
        scope.copy = ->
            scope.reports.copy({postEdit})
            $location.path('/reporting/reports')
        scope.delete = ->
            scope.schedule.reportTemplatesListModel.remove(scope.model)
            scope.schedule.reportTemplatesListModel.add() if scope.schedule.reportTemplatesListModel.available.length is 0
        scope.showDeleteButton = ->
            scope.schedule.reportTemplatesListModel.available.length > 1
]

module.directive 'scheduleReportTemplateSelect', [() ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="schedule-report-template-select">
        <select ng-options="report as (report.label) group by (report.template.label) for report in view.available" ng-model="view.selected">
            <option value="" ng-if="!view.selected">Select a report...&nbsp;</option>
        </select>
    </article>
    """
    link: (scope) ->
        scope.view = {modelHash:null}

        modelChanged = ->
            current = do ->
                return null if not scope.model
                return Utils.Object.hash getAvailable(scope.model)
            previous = scope.view.modelHash
            scope.view.modelHash = current
            return current is previous

        getAvailable = (model) ->
            _.flatten model.available.map (template) ->
                reports = template.reports.viewState.available or []
                reports.map (report) -> {id:report.id, label:report.label, template:{id:template.id, label:template.label}}

        scope.$watch 'view.selected', (selected) ->
            return if not selected
            scope.model.selectByReportId(selected.id)

        scope.$watch 'model.selected.reports.viewState.active.id', (reportId, prev) ->
            return if not scope.model?.selected
            return if reportId is prev
            report = _.find scope.view.available, (x) -> x.id is reportId
            report = report or scope.view.available?[0]
            scope.view.selected = report if report

        scope.$watch modelChanged, ->
            scope.view.available = do ->
                return [] if not scope.model
                return getAvailable(scope.model)
            scope.view.selected = do ->
                return null if not scope.model?.selected
                reportId = scope.model.selected.reports.viewState.active.id
                report = _.find scope.view.available, (x) -> x.id is reportId
                return report or scope.view.available[0]
]

module.directive 'viewReportingSchedules', ['ReportingState', 'CronExpressionUtils', (ReportingState, CronExpressionUtils) ->
    restrict: 'E'
    scope:
        model: '='
    template: \
    """
    <article class="view view-reporting view-reporting-list view-reporting-schedules" ng-class="{invalid:ReportingState.schedule.flags.isInvalid, unsaved:ReportingState.schedule.flags.hasUnsavedChanges}">
        <div class="loadable" ng-class="{loading: model === null}"></div>
        <aside><schedules-list model="model"></schedules-list></aside>
        <main ng-show="model.viewState.active">
            <view-reporting-schedules-header ng-if="model" model="model"></view-reporting-schedules-header>
            <main>
                <article class="schedule-editor">
                    <article class="schedule-info schedule-info-editor">
                        <main>
                            <label class="schedule-name">
                                <span>Label</span>
                                <input type="text" ng-model="model.viewState.active.label"></input>
                            </label>
                            <div class="row-schedule-expression" ng-class="{error:!model.viewState.active.expression}">
                                <label>
                                    <span>Frequency</span>
                                    <span ng-if="translatedExpression" class="hint">({{ translatedExpression }})</span>
                                </label>
                                <span class="hint error-message" ng-if="errorMessage">{{ errorMessage }}</span>
                                <!-- <span class="hint">({{ model.viewState.active.expression }})</span> -->
                                <schedule-expression-editor
                                    ng-if="model.viewState.active"
                                    model="model.viewState.active"
                                    error-message="errorMessage">
                                </schedule-expression-editor>
                            </div>
                            <label class="schedule-timezone">
                                <span>Timezone</span>
                                <span class="hint">(current time: {{ currentTime }})</span>
                                <select ng-options="x.id as (x.label) group by (x.group) for x in model.TIMEZONES" ng-model="model.viewState.active.timezone"></select>
                            </label>
                        </main>
                    </article>
                </article>
                <section class="report-templates">
                    <section class="report-template" ng-repeat="reportTemplates in model.reportTemplatesListModel.available track by reportTemplates.id">
                        <schedule-report-editor model="reportTemplates" schedule="model"></schedule-report-editor>
                    </section>
                    <section class="report-templates-actions">
                        <div class="ui-button" ng-if="showReportAddButton()" ng-click="model.reportTemplatesListModel.add()">
                            <i class="icon-plus-circled"></i>
                            <span>Add Report</span>
                        </div>
                    </section>
                </section>
                <schedule-target-editor model="model.viewState.active.data.target"></schedule-target-editor>
            </main>
        </main>
    </article>
    """
    link: (scope) ->
        scope.ReportingState = ReportingState
        scope.translatedExpression = null

        scope.$watch 'model.hasUnsavedChanges()', (unsavedChanges) ->
            ReportingState.schedule.flags.hasUnsavedChanges = unsavedChanges

        scope.$watch 'model.isInvalid()', (invalid) ->
            ReportingState.schedule.flags.isInvalid = invalid

        scope.$watch 'model.viewState.active', ((active) ->
            scope.currentTime = do ->
                timezone = active?.timezone
                return null if not timezone
                return moment.tz(timezone).format('MMMM DD, h:mma Z')
            scope.translatedExpression = do ->
                expression = active?.expression
                return try CronExpressionUtils.cronToNaturalLanguage(expression) or null
        ), true

        hashReportTemplatesList = ->
            return if not scope.model?.reportTemplatesListModel
            result = scope.model.reportTemplatesListModel.available.map (x) -> x.selected?.reports?.viewState?.selected?.id
            return result.join('')

        hashSelectedReports = ->
            reportIds = scope.model?.viewState.active?.data.reportId ? []
            reportIds = [reportIds] if _.isString(reportIds)
            return reportIds.join('')

        scope.$watch hashSelectedReports, () ->
            scope.model?.updateReportTemplatesFromModel()

        scope.$watch hashReportTemplatesList, (hash) ->
            return if not hash
            scope.model?.updateModelFromReportTemplates()

        scope.showReportAddButton = ->
            available = scope.model?.reportTemplatesListModel?.available
            return false if not available or available.length is 0
            last = available[available.length-1]
            return last?.selected?.reports?.viewState.selected
]


module.directive 'scheduleListItem', ['CronExpressionUtils', (CronExpressionUtils) ->
    restrict: "E"
    scope:
        schedule: "="
        selected: "="
        view: "="
    replace: true
    template: \
    """
    <li class="schedule" ng-class="{selected: selected}">
        <h1 class="schedule-label">{{ schedule.label }}</h1>
        <section class="schedule-report-labels" ng-class="{error: getView(schedule).reportLabels.length == 0}">
            <h2 class="schedule-report-error">No reports assigned!</h2>
            <h2 ng-if="getView(schedule).reportLabelsHeader">{{ getView(schedule).reportLabelsHeader }}</h2>
            <span class="schedule-report-label" ng-repeat="label in getView(schedule).reportLabels track by $index">
                {{ label }}
            </span>
        </section>
        <span class="schedule-expression-label">{{ getScheduleExpressionLabel(schedule) }}</span>
    </li>
    """
    link: (scope, element) ->

        scope.getView = (schedule) ->
            scope.view[schedule.id]

        scope.getScheduleExpressionLabel = (schedule) ->
            CronExpressionUtils.expressionToNaturalLanguage(schedule.expression)

        scope.$watch 'selected', ->
            return if not scope.selected
            listContainerBottomHeigth = element.parent()[0].getBoundingClientRect().bottom
            elementBottomHeight = element[0].getBoundingClientRect().bottom
            element[0].scrollIntoView() if elementBottomHeight > listContainerBottomHeigth
]


module.directive 'schedulesList', ['$location', 'CronExpressionUtils', ($location, CronExpressionUtils) ->
    restrict: "E"
    scope:
        schedules: "=model"

    template: \
    """
    <article class="list-container schedules list-container-schedules" ng-if="schedules">
        <article class="list-action list-action-create schedule schedule-create" ng-if="!schedules.isInCreateMode()" ng-click="createNewSchedule()">
            <div class="ui-button">
                <i class="icon-plus-circled"></i>
                <span>Create Schedule</span>
            </div>
        </article>

        <article class="list-action list-action-create schedule schedule-create"
            ng-if="schedules.isInCreateMode()"
            ng-click="schedules.cancel()">
            <div class="ui-button">
                <i class="icon-cancel-circled"></i>
                <span>Cancel</span>
            </div>
        </article>

        <article class="list-action list-action-search"
            ng-if="schedules.available.length > 1 && !schedules.isInCreateMode()"
            ng-class="{filtered:scheduleFilter.label}">
            <i class="icon-cancel-circled" ng-click="scheduleFilter.label = ''" ng-show="scheduleFilter.label"></i>
            <input ng-model="scheduleFilter.label" type="text" placeholder="Filter..." />
            <i class="icon-search"></i>
        </article>

        <ul class="schedules-list">
            <li class="schedule schedule-new selected" ng-if="schedules.isInCreateMode()">
                <h1 class="schedule-label">{{ schedules.viewState.active.label }}</h1>
                <span class="schedule-report-label" ng-repeat="label in getReportLabels(schedules)">{{ label }}</span>
                <span class="schedule-expression-label">{{ getScheduleExpressionLabel(schedules.viewState.active) }}</span>
            </li>
            <schedule-list-item
                ng-repeat="schedule in schedules.available | filter:scheduleFilter | orderBy:'createdAt':-1"
                ng-click="selectSchedule(schedule)"
                schedule="schedule"
                view="view.schedules"
                selected="schedule.id === schedules.viewState.active.id">
            </schedule-list-item>
        </ul>
    </article>
    """
    link: (scope) ->
        scope.view = {schedules:{}}

        scope.createNewSchedule = ->
            scope.schedules.create()

        scope.scheduleFilter = {label:''}

        scope.selectSchedule = (schedule) ->
            if schedule.id isnt scope.schedules.viewState.active.id
                $location.path(scope.schedules.getScheduleLink(schedule), false)
                scope.schedules.select(schedule)

        scope.getScheduleExpressionLabel = (schedule) ->
            CronExpressionUtils.expressionToNaturalLanguage(schedule.expression)

        getReportLabels = (schedule, schedules) ->
            reportIds = schedule.data.reportId
            reports = schedules.reportTemplatesListModel.getReportDataById(reportIds)
            return reports.map (x) -> x.label

        scope.getView = (schedule) ->
            scope.view.schedules[schedule.id]

        watchReportLabels = ->
            result = _.flatten (scope.schedules?.available or []).map (schedule) ->
                labels = getReportLabels(schedule, scope.schedules)
                return [schedule.id].concat(labels)
            return result.join('')

        scope.$watch watchReportLabels, ->
            scope.view.schedules = (scope.schedules?.available or []).reduce ((result, schedule) ->
                labels = getReportLabels(schedule, scope.schedules)
                result[schedule.id] =
                    reportLabels: labels
                    reportLabelsHeader: do ->
                        return 'Reports' if labels.length > 1
                        return 'Report'  if labels.length is 1
                        return null
                return result
            ), {}
]

module.directive 'viewReportingSchedulesHeader', ['$timeout', 'promiseTracker', 'ReportingState', ($timeout, promiseTracker, ReportingState) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <header class="view-reporting-list-header view-reporting-list-schedules-header">
        <section class="actions">
            <button class="button-run" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" promise-tracker="runScheduleTracker" ng-click="actions.run()">Run</button>
            <button class="button-save" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" ng-click="actions.save()" promise-tracker="updateScheduleTracker">Save</button>
            <button class="button-cancel button-bare" ng-click="actions.cancel()" ng-if="model.isInCreateMode()">Cancel</button>
            <button class="button-copy" ng-click="actions.copy()" ng-if="!model.isInCreateMode()">Save As...</button>
            <button class="button-reset button-bare"  ng-click="actions.reset()" ng-if="!model.isInCreateMode()">Reset</button>
            <button class="button-delete button-bare" ng-click="actions.delete()" ng-if="!model.isInCreateMode()">Delete</button>
            <button class="button-bare" ng-click="actions.toggleActive()" ng-if="!model.isInCreateMode()" promise-tracker="updateScheduleTracker">{{activeButtonLabel}}</button>
        </section>
    </header>
    """
    link: (scope) ->
        scope.runScheduleTracker = promiseTracker()
        scope.updateScheduleTracker = promiseTracker({ activationDelay: null })

        scope.errorMessage = ""
        scope.onSaveOrRunHover = ->
            scope.errorMessage = do ->
                return "" if not ReportingState.schedule.flags.isInvalid
                invalidFields = scope.model.getInvalidFields()
                return "Invalid or missing field(s): #{Object.keys(invalidFields).join(', ')}."

        scope.$watch 'model.viewState.active.active', (isActive) ->
            $timeout (->
                scope.activeButtonLabel = do ->
                    return 'Activate' if not isActive
                    return 'Deactivate'
            ), 50
            scope.actions =
                save: ->
                    return if not ReportingState.schedule.flags.hasUnsavedChanges
                    return if ReportingState.schedule.flags.isInvalid
                    scope.updateScheduleTracker.addPromise do ->
                        scope.model.save().catch((error) -> console.error(error))

                reset: ->
                    return if not ReportingState.schedule.flags.hasUnsavedChanges
                    return scope.model.reset()
                cancel: ->
                    return scope.model.cancel()
                copy: ->
                    return scope.model.copy()
                delete: ->
                    return if not window.confirm("""
                    Are you sure you want to delete this schedule?
                    This cannot be un-done.
                    """)
                    return scope.model.delete()
                toggleActive: ->
                    scope.updateScheduleTracker.addPromise do ->
                        scope.model.toggleActiveState()
                run: ->
                    return if ReportingState.schedule.flags.isInvalid
                    scope.runScheduleTracker.addPromise do ->
                        scope.model.run()
                        .then(-> alert("The schedule was run successfully!"))
                        .catch (error) ->
                            console.error('Error occurred after running schedule:', error)
                            alert("Could not run schedule due to error... sorry!")
]


module.directive 'scheduleInfoEditor', ['CronExpressionUtils', (CronExpressionUtils) ->
    restrict: "E"
    scope:
        model: "="
        reports: "="
    template: \
    """
    <article class="schedule-info schedule-info-editor">
        <main>
            <label class="schedule-name">
                <span>Label</span>
                <input type="text" ng-model="model.viewState.active.label"></input>
            </label>
            <div class="row-schedule-expression" ng-class="{error:!model.viewState.active.expression}">
                <label>
                    <span>Frequency</span>
                    <span ng-if="translatedExpression" class="hint">({{ translatedExpression }})</span>
                </label>
                <span class="hint error-message" ng-if="errorMessage">{{ errorMessage }}</span>
                <!-- <span class="hint">({{ model.viewState.active.expression }})</span> -->
                <schedule-expression-editor model="model.viewState.active" reports="reports" error-message="errorMessage"></schedule-expression-editor>
            </div>
            <label class="schedule-timezone">
                <span>Timezone</span>
                <span class="hint">(current time: {{ currentTime }})</span>
                <select ng-options="x.id as (x.label) group by (x.group) for x in model.TIMEZONES" ng-model="model.viewState.active.timezone"></select>
            </label>
        </main>
    </article>
    """
    link: (scope) ->
        scope.naturalLanguageExpression = null
        scope.$watch('model.viewState', ->
            timezone = scope.model?.viewState?.active?.timezone
            scope.currentTime = do ->
                return null if not timezone
                return moment.tz(timezone).format('MMMM DD, h:mma Z')
            expression = scope.model?.viewState?.active?.expression
            scope.naturalLanguageExpression = try CronExpressionUtils.expressionToNaturalLanguage(expression)
        , true)
]



module.directive 'reportParamsViewer', ['ReportParamsFilterItemsModel', 'ReportParamsFilterStoresModel', (ReportParamsFilterItemsModel, ReportParamsFilterStoresModel) ->
    restrict: "E"
    scope:
        params: "="
    replace: true
    template: \
    """
    <article class="report-params-container report-params-viewers">
        <report-params-viewer-filter ng-repeat="model in models.filters" model="model"></report-params-viewer-filter>
        <report-params-viewer-timerange params="params"></report-params-viewer-timerange>
        <report-params-viewer-group-by params="params"></report-params-viewer-group-by>
        <report-params-viewer-metric-select params="params"></report-params-viewer-metric-select>
    </article>
    """
    link: (scope) ->
        scope.models = {}
        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            scope.models =
                filters: [
                    new ReportParamsFilterItemsModel(params)
                    new ReportParamsFilterStoresModel(params)
                ]
]

module.directive 'reportParamsEditor', [() ->
    restrict: "E"
    scope:
        model: "="
    template: \
    """
    <article class="report-params-editors" ng-if="model.selected.params.length > 0">
        <section class="report-params-editor-group" ng-repeat="availableParams in editorGroups">
            <div ng-repeat="paramType in availableParams" class="report-params">
                <ng-switch on="paramType">
                    <report-params-metric-select     params="params" ng-switch-when="metrics"></report-params-metric-select>
                    <report-params-column-properties params="params" ng-switch-when="columns"></report-params-column-properties>
                    <report-params-column-style      params="params" ng-switch-when="columnStyle"></report-params-column-style>
                    <report-params-timerange         params="params" ng-switch-when="timerange"></report-params-timerange>
                    <report-params-group-by          params="params" ng-switch-when="hierarchyStore"></report-params-group-by>
                    <report-params-sort              params="params" ng-switch-when="sort"></report-params-sort>
                    <report-params-filter-stores     params="params" ng-switch-when="filterStores"></report-params-filter-stores>
                    <report-params-filter-items      params="params" ng-switch-when="filterItems"></report-params-filter-items>
                    <report-params-currency          params="params" ng-switch-when="currency"></report-params-currency>
                </ng-switch>
            </div>
        </section>
    </article>
    """
    link: (scope) ->
        scope.$watch 'model.selected', (selected) ->
            available = selected?.params or []
            scope.editorGroups = [
                _.intersection(available, ['filterStores', 'filterItems'])
                _.difference(available, ['filterStores', 'filterItems'])
            ].filter (x) -> x.length > 0
        scope.$watch 'model.selected.reports.getActiveParams()', (params) ->
            scope.params = params
]

module.factory('ReportParamsFilterModel', ['$q', 'ReportSmartGroups', 'SchedulingHierarchy', ($q, ReportSmartGroups, SchedulingHierarchy) ->
    return class ReportParamsFilterModel
        constructor: (@title, @descriptor, @filters) ->
            @descriptor = _.cloneDeep(@descriptor)

        init: ->
            @refresh()

        refresh: ->
            $q.when(SchedulingHierarchy.fetch()).then (properties) =>
                @_updateFromProperties(properties)
                return

        reset: ->
            Object.keys(@filters).forEach (key) => delete @filters[key]

        editFilter: ->
            popupState = _.cloneDeep({@descriptor, @filters})
            ReportSmartGroups.popup.open popupState, (update) =>
                Object.keys(@filters).forEach (key) => delete @filters[key]
                Object.keys(update).forEach (key) -> delete update[key] if _.isEmpty(update[key])
                # Using Object.assign to make sure we keep the same @filters ref
                # Unclear if this is required by the rest of the code, but this was the old behavior
                Object.assign(@filters, update)
                return

        _updateFromProperties: (properties) ->
            properties = properties.map((p) -> p.id)
            availableTables = _.uniq(properties.map((id) -> id.split('.')[0]))
            unsupportedTables = _.difference(Object.keys(@filters), availableTables)
            unsupportedTables.forEach (t) => delete @filters[t]
            Object.keys(@filters).forEach (key) =>
                @filters[key].$and = (@filters[key].$and ? []).filter (property) ->
                    propertyId = "#{key}.#{Object.keys(property)[0]}"
                    return properties.includes(propertyId)
                delete @filters[key].$and if @filters[key].$and.length is 0
            return
])


module.factory 'ReportParamsFilterItemsModel', ['ReportParamsFilterModel', 'CONFIG', (ReportParamsFilterModel, CONFIG) ->
    return (params) ->
        throw new Error("Missing required `params` argument.") if not params
        descriptor = _.cloneDeep(SmartGroupFilterDescriptors.get(CONFIG).find (x) -> x.id is 'items')
        params.filterItems ?= {}
        label = CONFIG.items?.label or "Item Filter"
        return new ReportParamsFilterModel(label, descriptor, params.filterItems)
]

module.factory 'ReportParamsFilterStoresModel', ['ReportParamsFilterModel', 'CONFIG', (ReportParamsFilterModel, CONFIG) ->
    return (params) ->
        throw new Error("Missing required `params` argument.") if not params
        descriptor = _.cloneDeep(SmartGroupFilterDescriptors.get(CONFIG).find (x) -> x.id is 'stores')
        params.filterStores ?= {}
        label = CONFIG.stores?.label or "Store Filter"
        return new ReportParamsFilterModel(label, descriptor, params.filterStores)
]

###*
@typedef {{
    table: string;
    column: string;
    value: string;
    exclude: boolean;
}} SchedulingExtractedQueryFilterValue
###

QueryObjectFilterUtils =

    ###*
    @argument {IQueryFilters} filters
    @argument {SchedulingExtractedQueryFilterValue} x
    @returns {IQueryFilters}
    ###
    deleteValue: (filters, x) ->
        throw new Error("Missing required `value.table` property.") if typeof x.table isnt 'string'
        throw new Error("Missing required `value.column` property.") if typeof x.column isnt 'string'
        throw new Error("Missing required `value.value` property.") if _.isNil(x.value)
        table = filters[x.table]?.$and
        return filters if not Array.isArray(table)
        column = table.find((group) -> Object.keys(group)?[0] is x.column)
        values = do ->
            return column[x.column]?.$nin if x.exclude
            return column[x.column]?.$in
        if Array.isArray(values)
            valueIndex = values.findIndex((v) -> v is x.value)
            values.splice(valueIndex, 1) if valueIndex >= 0
        if values.length is 0
            columnIndex = table.findIndex((group) -> Object.keys(group)?[0] is x.column)
            table.splice(columnIndex, 1) if columnIndex >= 0
        if table.length is 0
            delete filters[x.table]
        return filters

    ###*
    @argument {Record<string, undefined | IQueryTableFilter>} filters
    @returns {SchedulingExtractedQueryFilterValue[]}
    ###
    getValues: (filters) ->
        ###* @type {SchedulingExtractedQueryFilterValue[]} ###
        result = []
        return result if _.isNil(filters)
        return _.cloneDeep Object.keys(filters).reduce ((result, table) ->
            columns = filters[table]?.$and
            return result if not Array.isArray(columns)
            for group from columns
                column = Object.keys(group)?[0]
                continue if typeof column isnt 'string'
                included = (group[column]?.$in ? [])
                excluded = (group[column]?.$nin ? [])
                result.push({table, column, value, exclude: false}) for value from included
                result.push({table, column, value, exclude: true}) for value from excluded
            return result
        ), result


module.directive('reportParamsFilterSelected', ['SchedulingHierarchy',
###*
@param {ISchedulingHierarchy} SchedulingHierarchy
@returns {angular.IDirective<angular.IScope & {
    view          : {filters: SelectionPebbleModel[]},
    filters       : IQueryFilters
    getFilterHash : () => null | string,
}>}
###
(SchedulingHierarchy) ->
    restrict: 'E'
    scope:
        filters: '='
    replace: true
    template: \
    """
    <article class="report-params-filter-selected">
        <selection-pebble ng-repeat="x in view.filters" model="x"></selection-pebble>
    </article>
    """
    link: (scope) ->

        scope.getFilterHash = ->
            return null if not scope.filters
            return Utils.Object.hash(scope.filters)

        getHierarchyById = do ->
            ###* @type {null | angular.IPromise<Record<string, IPropertyDefinition>>} ###
            cache = null
            return ->
                cache ?= SchedulingHierarchy.fetch().then((x) -> _.keyBy(x, 'id'))
                return cache.then((result) -> _.cloneDeep(result))

        getSelected = (filters) ->
            selected = QueryObjectFilterUtils.getValues(filters)
            return getHierarchyById()
            .catch (error) ->
                console.error "Could not get hierarchy to update filter display:"
                console.error error
                return null
            .then (hierarchy) -> selected.flatMap (filter) ->
                property = HierarchyLib.normalizePropertyDefinition(filter)
                property = {...property, ...(hierarchy?[property.id] ? {})}
                return [{filter, property}]

        scope.view = {filters: []}
        scope.$watch 'getFilterHash()', ->
            return (scope.view.filters = []) if not scope.filters
            return getSelected(scope.filters).then (selected) ->
                scope.view.filters = selected.map ({filter, property}) ->
                    id: property.id
                    label: property.label
                    value: filter.value
                    draggable: false
                    onClick: ->
                        QueryObjectFilterUtils.deleteValue(scope.filters, filter)
                        return
                    icon: 'icon-cancel-circled'
                    color: do ->
                        return 'red' if filter.exclude
                        return 'neutral'
])


module.constant 'PreventScrollEventBubblingEventHandler', (e) ->
    # Usage:
    # $(element).on 'DOMMouseScroll mousewheel', PreventScrollEventBubblingEventHandler
    up = false
    up = e.originalEvent.wheelDelta / -1 < 0 if e.originalEvent?.wheelDelta
    up = e.originalEvent.deltaY < 0          if e.originalEvent?.deltaY
    up = e.originalEvent.detail < 0          if e.originalEvent?.detail


module.directive 'reportParamsFilterStores', ['ReportParamsFilterStoresModel', (ReportParamsFilterStoresModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params-filter-stores">
        <report-params-filter model="model"></report-params-filter>
    </article>
    """
    link: (scope) ->
        scope.model = null
        scope.$watch 'params', (params) ->
            scope.model = do ->
                return null if not params
                return new ReportParamsFilterStoresModel(params)
]


module.directive 'reportParamsFilterItems', ['ReportParamsFilterItemsModel', (ReportParamsFilterItemsModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params-filter-items">
        <report-params-filter model="model"></report-params-filter>
    </article>
    """
    link: (scope) ->
        scope.model = null
        scope.$watch 'params', (params) ->
            return (scope.model = null) if not params
            scope.model = new ReportParamsFilterItemsModel(params)
            scope.model.init()
]

module.directive 'reportParamsFilter', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-filter">
        <header>
            <h1>{{ model.title }}</h1>
            <section class="hint-right">
                <button class="button-edit-filters" ng-click="model.editFilter()">
                    <i class="icon-pencil"></i>
                    Edit Filter
                </button>
                <button class="button-bare action" ng-click="model.reset()">reset</button>
            </section>
        </header>
        <main>
            <report-params-filter-selected filters="model.filters"></report-params-filter-selected>
        </main>
    </article>
    """


module.directive 'reportParamsColumnProperties', ['ReportParamsColumnPropertiesModel', (ReportParamsColumnPropertiesModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-column-properties">
        <header>
            <h1>How should the report's columns be broken down?</h1>
        </header>
        <main>
            <ul class="pellets">
                <li class="pellet null" ng-click="select()" ng-class="{selected:!model.selected}">None</li>
                <li class="pellet" ng-click="select(item)" ng-class="{selected:item === model.selected}" ng-repeat="item in model.available">
                    {{ item.label }}
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope) ->
        scope.select = (item) ->
            scope.model.selected = item or null
            scope.model.updateParamsFromModel()

        scope.$watch 'params', (params) ->
            if not scope.model
                scope.model = new ReportParamsColumnPropertiesModel(params)
                scope.model.init()
            else
                scope.model.params = params
                scope.model.updateModelFromParams()
]

module.directive('reportParamsViewerFilter', ['SchedulingHierarchy',
###*
@argument {ISchedulingHierarchy} SchedulingHierarchy
@returns {angular.IDirective<angular.IScope & {
    view  : {selected: {}[]},
    model : {title: string, filters: IQueryFilters},
}>}
###
(SchedulingHierarchy) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="report-params-filter report-params-viewer-filter">
        <article class="report-params" ng-if="view.selected && view.selected.length > 0">
            <header>
                <h1>{{ model.title }}</h1>
            </header>
            <main>
                <article class="report-params-filter-selected">
                    <ul>
                        <li class="selection-pebble" ng-repeat="x in view.selected" ng-class="{'pebble-color-red': x.filter.exclude }">
                            <div class="pebble-content">
                                <span class="pebble-label">{{ x.property.label }}</span>
                                <span class="pebble-value">{{ x.filter.value }}</span>
                            </div>
                        </li>
                    </ul>
                </article>
            </main>
        </article>
    </article>
    """
    link: (scope) ->

        getHierarchyById = do ->
            ###* @type {null | angular.IPromise<Record<string, IPropertyDefinition>>} ###
            cache = null
            return ->
                cache ?= SchedulingHierarchy.fetch().then((x) -> _.keyBy(x, 'id'))
                return cache.then((result) -> _.cloneDeep(result))

        getSelected = (filters) ->
            selected = QueryObjectFilterUtils.getValues(filters)
            return getHierarchyById()
            .catch (error) ->
                console.error "Could not get hierarchy to update filter display:"
                console.error error
                return null
            .then (hierarchy) -> selected.map (filter) ->
                property = HierarchyLib.normalizePropertyDefinition(filter)
                property = {...property, ...(hierarchy?[property.id] ? {})}
                return {filter, property}

        scope.view = {selected: []}
        scope.$watch 'model.filters', (filters) ->
            return (scope.view.selected = []) if not filters
            return getSelected(filters).then (x) -> scope.view.selected = x
])


module.directive('reportParamsHierarchy', ['ReportingState', (ReportingState) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="report-params-hierarchy" ng-class="{invalid:isInvalid}">
        <report-params-hierarchy-list
            class="report-params-hierarchy-list-available"
            list-title="Available Properties"
            help="{{ text }}"
            model="model"
            variant="add"
            group="properties"
            other="selected"
        >
            <span ng-if="model.selected.length == 0">Drag or double-click on the properties below to select them.</span>
            <button class="button-bare action" ng-if="model.selected.length >= 1" ng-click="model.resetSelected()">reset</button>
        </report-params-hierarchy-list>
        <report-params-hierarchy-list
            class="report-params-hierarchy-list-selected"
            list-title="Selected Properties"
            model="model"
            variant="remove"
            group="selected"
            other="properties"
        >
            <button class="button-bare action" ng-if="model.selected.length >= 1" ng-click="model.resetSelected()">reset</button>
        </report-params-hierarchy-list>
    </article>
    """
    link: (scope) ->
        scope.$watch 'model.selected.length', ->
            return if not scope.model
            ReportingState.report.updateInvalidFields(scope.model.getInvalidFields())
])


module.directive 'reportParamsHierarchyList', do -> \
###*
@returns {angular.IDirective<angular.IScope & {
    view          : {properties: SelectionPebbleModel[]},
    model         : {available: IPropertyDefinition[], properties: IPropertyDefinition[], selected: IPropertyDefinition[]},
    variant       : 'add' | 'remove'
    group         : 'selected' | 'properties',
    other         : 'selected' | 'properties',
}>}
###
return () ->
    restrict: 'E'
    scope:
        listTitle : '@'
        help      : '@'
        model     : '='
        variant   : '@'
        group     : '@'
        other     : '@'
    transclude: true
    replace: true
    template: \
    """
    <article class="report-params-hierarchy-list">
        <header>
            <h1>{{ listTitle }}</h1>
            <h2 class="hint hint-right" ng-transclude></span>
        </header>
        <main>
            <ul class="properties sortable-ui">
                <li class="property" ng-attr-data-property-id="{{p.id}}" ng-repeat="p in view.properties">
                    <selection-pebble model="p"></selection-pebble>
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope, element) ->
        cleanupSortable = do ->
            sortable = null
            setTimeout (->
                sortableEl = element[0]?.querySelector('.sortable-ui')
                throw new Error("element not found: .sortable-ui") if not sortableEl
                sortable = createSortable sortableEl,
                    ghostClass: 'placeholder'
                    draggable: '.property'
                    dragClass: 'dragging'
                    dataIdAttr: 'data-property-id'
                    fallbackTolerance: 2
                    forceFallback: true
                    group: {name: scope.group, put: ["selected", "properties"]}
                    onStart: ->
                        console.log("[reporting][properties][#{scope.group}]", "dragging started")
                        document.querySelector('.report-params-hierarchy')?.classList.add('dragging')
                        return
                    onEnd: ->
                        console.log("[reporting][properties][#{scope.group}]", "dragging ended")
                        document.querySelector('.report-params-hierarchy')?.classList.remove('dragging')
                        return
                    onUpdate: (evt) ->
                        return if typeof evt.newIndex isnt 'number'
                        return if typeof evt.oldIndex isnt 'number'
                        console.log("[reporting][properties][#{scope.group}]", "update", evt.oldIndex, evt.newIndex)
                        scope.model[scope.group] = Utils.Array.move(scope.model[scope.group], evt.oldIndex, evt.newIndex)
                        return
                    onAdd: (evt) ->
                        return if typeof evt.newIndex isnt 'number'
                        item = _.cloneDeep scope.model.available.find((x) -> x.id is evt.item.dataset.propertyId)
                        return if not item
                        console.log("[reporting][properties][#{scope.group}]", "add", evt.newIndex, item)
                        scope.model[scope.group] = Utils.Array.insertAt(scope.model[scope.group], evt.newIndex, item)
                        return
                    onRemove: (evt) ->
                        return if typeof evt.oldIndex isnt 'number'
                        console.log("[reporting][properties][#{scope.group}]", "remove", evt.oldIndex)
                        scope.model[scope.group] = Utils.Array.removeAt(scope.model[scope.group], evt.oldIndex)
                        return
            ), 200
            return ->
                document.querySelector('.report-params-hierarchy')?.classList.remove('dragging')
                sortable?.destroy()
        scope.$on('$destroy', cleanupSortable)

        ###* @argument {undefined | IPropertyDefinition} item ###
        toggle = (item) ->
            item = _.cloneDeep(scope.model.available.find((x) -> x.id is item?.id))
            return if not item
            index = scope.model[scope.group].findIndex((x) -> x.id is item?.id)
            return if index is -1
            console.log("[reporting][properties][#{scope.group}][#{index}]", "moving", item.id, "to", scope.other)
            scope.model[scope.group] = Utils.Array.removeAt(scope.model[scope.group], index) if index > -1
            scope.model[scope.other].push(item)
            return

        ###* @argument {undefined | IPropertyDefinition[]} properties ###
        getView = (properties) ->
            return [] if not Array.isArray(properties)
            variantOptions = do ->
                return {color:'blue', icon:'icon-plus-circled'} if scope.variant is 'add'
                return {color:'neutral', icon:'icon-cancel-circled'}
            return properties.map (property) -> {
                id            : property.id
                value         : property.label
                draggable     : true
                onDoubleClick : toggle.bind(null, property)
                onIconClick   : toggle.bind(null, property)
                ...variantOptions
            }

        scope.view = {properties:[]}
        scope.$watch((-> scope.model?[scope.group]), ((properties) ->
            scope.view.properties = getView(properties)
            return
        ), true)



module.directive 'reportParamsViewerGroupBy', ['ReportParamsViewerGroupByModel', (ReportParamsViewerGroupByModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params-viewer report-params-viewer-hierarchy-store">
        <header>
            <h1>How should the report be broken down?</h1>
        </header>
        <main ng-if="model.selected">
            <ul class="properties">
                <li class="ui-pellet active disabled" ng-repeat="property in model.selected">
                    {{ property.label }}
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope) ->
        scope.model = new ReportParamsViewerGroupByModel()
        scope.$watch 'params', (params) ->
            return if _.isNil(params)
            scope.model.updateModelFromParams(params)
]

# TODO: rename class: report-params-hierarchy-store -> report-params-group-by
module.directive 'reportParamsGroupBy', ['$rootScope', '$q', 'ReportParamsGroupByModel', ($rootScope, $q, ReportParamsGroupByModel) ->
    restrict: 'E'
    scope: params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-hierarchy-store">
        <header>
            <h1>How should the report's rows be broken down?</h1>
        </header>
        <report-params-hierarchy model="model"></report-params-hierarchy>
    </article>
    """
    link: (scope) ->
        watchers = []

        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)

            watchers.forEach (x) -> x() # unregisters the existing watchers
            watchers = []

            modelPromise = do ->
                if not scope.model
                    scope.model = new ReportParamsGroupByModel(params)
                    scope.model.init()
                else
                    scope.model.resetSelected()
                    scope.model.params = params
                    scope.model.updateModelFromParams()
                    return $q.when()
            modelPromise.then ->
                watchers.push \
                    scope.$watch 'model.selected', ((properties) ->
                        return if _.isUndefined(properties)
                        scope.model.updateParamsFromModel()
                    ), true
                watchers.push \
                    scope.$watch 'params.hierarchyStore', (->
                        scope.model.updateModelFromParams()
                    ), true

            .catch (error) ->
                console.error error
                scope.$emit 'report-params-error',
                    error: error
                    message: "Could not load store hierarchy."
                scope.error = true
                throw error
]

module.directive 'reportParamsCurrency', ['$q', 'ReportParamsCurrencyModel', ($q, ReportParamsCurrencyModel) ->
    restrict: 'E'
    scope: params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-currency">
        <header>
            <h1>What currency do you want to see?</h1>
        </header>
        <select ng-options="x as (x.label) for x in model.available" ng-model="model.selected"></select>
    </article>
    """
    link: (scope) ->
        watchers = []

        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            watchers.forEach (x) -> x() # unregisters the existing watchers
            watchers = []
            modelPromise = do ->
                if not scope.model
                    scope.model = new ReportParamsCurrencyModel(params)
                    scope.model.init()
                else
                    scope.model.resetSelected()
                    scope.model.params = params
                    scope.model.updateModelFromParams()
                    return $q.when()
            modelPromise.then ->
                watchers.push \
                    scope.$watch 'model.selected', ((properties) ->
                        return if _.isUndefined(properties)
                        scope.model.updateParamsFromModel()
                    ), true
                watchers.push \
                    scope.$watch 'params.currency', (->
                        scope.model.refresh()
                    ), true
            .catch (error) ->
                console.error error
                scope.$emit 'report-params-error',
                    error: error
                    message: "Could not load currencies."
                scope.error = true
                throw error
]


module.factory('ReportParamsColumnPropertiesModel', ['SchedulingHierarchy', (SchedulingHierarchy) ->
    return class ReportParamsColumnPropertiesModel

        constructor: (@params) ->
            ###* @type {IPropertyDefinition[]} available ###
            @available = []
            ###* @type {null | undefined | IPropertyDefinition} selected ###
            @selected  = null

        init: ->
            @refresh()

        refresh: ->
            SchedulingHierarchy.fetch().then (properties) =>
                @selected = null
                @available = properties.filter (x) ->
                    return x.id.startsWith('calendar.') and not x.id.includes('calendar.day_of_week_label')
                @updateModelFromParams()

        updateParamsFromModel: ->
            @params.columns = _.compact([@selected?.id])

        updateModelFromParams: ->
            @selected = do =>
                id = @params?.columns?[0]
                return null if not id
                return _.find @available, (x) -> x.id is id
])

# The params key for the group-by properties of the report.
# Named this way for historical reasons...
PARAMS_KEY_GROUP_BY = 'hierarchyStore'

module.factory 'ReportParamsGroupByModel', ['$q', 'SchedulingHierarchy', ($q, SchedulingHierarchy) ->
    return class ReportParamsGroupByModel
        constructor: (@params) ->
            @initialized = false
            ###* @type {IPropertyDefinition[]} available ###
            @available = []
            ###* @type {IPropertyDefinition[]} available ###
            @properties = []
            ###* @type {IPropertyDefinition[]} available ###
            @selected = []
        init: ->
            @refresh()
        refresh: ->
            SchedulingHierarchy.fetch().then (properties) =>
                @initialized = true
                @available = _.cloneDeep(properties)
                @properties = properties
                @selected = []
                @updateModelFromParams()
        resetSelected: ->
            @properties = _.cloneDeep(@available)
            @selected = []
            @updateParamsFromModel()
        getInvalidFields: ->
            result = {}
            result['Breakdown Properties'] = @selected.length is 0
            return result
        updateParamsFromModel: ->
            @params[PARAMS_KEY_GROUP_BY] = @selected.map (x) -> x.id
            selectedById = _.keyBy @selected, (x) -> x.id
            @properties = @properties.filter (x) -> not selectedById[x.id]
        updateModelFromParams: ->
            availableById = _.keyBy(@available, (x) -> x.id)
            selectedIds = [...(@params[PARAMS_KEY_GROUP_BY] or [])]
            @selected = selectedIds.flatMap (id) ->
                property = availableById[id]
                return _.cloneDeep(property) if property
                console.error("Property `#{id}` was not found in list of available properties.") if not property
                return []
            selectedById = _.keyBy @selected, (x) -> x.id
            @properties = @properties.filter (x) -> not selectedById[x.id]
]


module.factory('ReportParamsViewerGroupByModel', ['$q', 'SchedulingHierarchy', ($q, SchedulingHierarchy) ->
    return class ReportParamsViewerGroupByModel
        constructor: ->
            @selected = null
        refresh: ->
            return SchedulingHierarchy.fetch().then (properties) -> _.keyBy(properties, (x) -> x.id)
        updateModelFromParams: (params) ->
            selectedIds = [...(params?[PARAMS_KEY_GROUP_BY])]
            return if _.isEqual(selectedIds, @selectedIds)
            @selectedIds = [...selectedIds]
            @selected = null
            @refresh().then (available) =>
                return if @selected isnt null
                @selected = @selectedIds.flatMap (id) ->
                    property = _.cloneDeep(available[id])
                    return property if property
                    console.error("Property `#{id}` was not found in list of available properties.") if not property
                    return []
            return
])


module.factory('ReportParamsCurrencyModel', ['$rootScope', ($rootScope) ->
    return class ReportParamsCurrencyModel
        constructor: (@params) ->
            ###* @type {ICurrency[]} available ###
            @available = []
            ###* @type {undefined | null | ICurrency} selected ###
            @selected = null
            @initialized = false
        init: ->
            @refresh()
        refresh: ->
            CurrenciesService.fetch().then (currencies) =>
                @initialized = true
                @available = currencies
                @updateModelFromParams()
        resetSelected: ->
            @updateParamsFromModel()
        updateParamsFromModel: ->
            @params.currency = @selected?.id
        updateModelFromParams: ->
            selected = _.find @available, (currency) => currency.id is @params.currency
            selected ?= _.find @available, (currency) -> currency.id is $rootScope.currencyModel?.selected?.id
            @selected = selected or @available[0]
])


module.service('SchedulingHierarchy', ['$q', 'Hierarchy', 'HourProperty', 'CalendarProperties',
###*
@argument {angular.IQService} $q
@argument {import('./../../modules/hierarchy').IHierarchyService} Hierarchy
@argument {{fetch(): angular.IPromise<null | IPropertyDefinition>}} HourProperty
@argument {{fetch(): angular.IPromise<null | IPropertyDefinition[]>}} CalendarProperties
@returns {ISchedulingHierarchy}
###
($q, Hierarchy, HourProperty, CalendarProperties) ->
    fetch: ->
        $q.all([
            Hierarchy.fetch().then((x) -> x.pebbles)
            CalendarProperties.fetch().then((x) -> x ? [])
            HourProperty.fetch().then((x) -> if x then [x] else [])
        ]).then ([hierarchy, calendar, hour]) ->
            configured = new Set hierarchy.map((x) -> x.id)
            hierarchy = [...hierarchy, ...calendar.filter((x) -> not configured.has(x.id))]
            hierarchy = [...hierarchy, ...hour.filter((x) -> not configured.has(x.id))]
            return hierarchy
])
