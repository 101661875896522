import _ from 'lodash';
import Utils from '../../../lib/utils';
import { IQueryMetrics } from '../../main-controller';
import { IReportData } from '../../../modules/services/scheduling.service';
import { IMetricDefinition } from '../../../lib/types';
import { AngularInjected } from '../../../lib/angular';

export interface IReportParamsMetrics {
    id: string;
    label: string;
    group: string;
    metrics: IMetricDefinition[];
}

export type IReportParamsMetricSelectModelFactory = AngularInjected<typeof ReportParamsMetricSelectModelFactory>;
export type IReportParamsMetricSelectModel = InstanceType<IReportParamsMetricSelectModelFactory>;

export const ReportParamsMetricSelectModelFactory = () => [
    'QueryMetrics',
    function ReportParamsMetricSelectModelFn(QueryMetrics: IQueryMetrics) {
        return class ReportParamsMetricSelectModel {
            available: IReportParamsMetrics[] = [];
            selected: IReportParamsMetrics[] = [];
            params: IReportData;

            constructor(params: IReportData) {
                this.params = params;
            }

            init() {
                return this.refresh();
            }

            refresh() {
                return this.fetchMetrics().then(metrics => {
                    this.available = this.getAvailableFromMetrics(metrics);
                    return this.updateModelFromParams();
                });
            }

            getInvalidFields(): Record<string, boolean> {
                return {
                    Metrics: this.selected.length === 0,
                };
            }

            reset() {
                this.selected = [];
            }

            select(item: IReportParamsMetrics) {
                if (this.isSelected(item)) return;
                this.selected.push(item);
            }

            remove(item: IReportParamsMetrics) {
                if (!this.isSelected(item)) return;
                this.selected = Utils.Array.remove(this.selected, x => x.id === item.id);
            }

            toggle(item: IReportParamsMetrics) {
                return this.isSelected(item) ? this.remove(item) : this.select(item);
            }

            selectAll() {
                return this.available.forEach(metric => this.select(metric));
            }

            isSelected(item: IReportParamsMetrics) {
                return !!this.selected.find(x => x.id === item.id);
            }

            protected fetchMetrics() {
                return QueryMetrics.fetch(this.params.currency);
            }

            protected getAvailableFromMetrics(metrics: IMetricDefinition[]): IReportParamsMetrics[] {
                const seen: Record<string, IReportParamsMetrics> = {};
                return metrics.reduce<IReportParamsMetrics[]>((result, metric) => {
                    const key = `${metric.headerGroup} - ${metric.headerName}`;

                    const available = (() => {
                        const availableMetric = seen[key];
                        if (availableMetric !== undefined) return availableMetric;
                        const x: IReportParamsMetrics = {
                            id: key,
                            label: metric.headerGroup,
                            group: metric.headerGroup,
                            metrics: [],
                        };
                        result.push(x);
                        seen[key] = x;
                        return x;
                    })();

                    available.metrics.push(metric);
                    return result;
                }, []);
            }

            updateParamsFromModel() {
                this.params.metrics = _.flatten(this.selected.map(x => _.cloneDeep(x.metrics)));
            }

            updateModelFromParams(params?: IReportData) {
                if (params) this.params = params;
                const availableByField = _.flatten(this.available).reduce<Record<string, IReportParamsMetrics>>(
                    (result, available) => {
                        available.metrics.forEach(x => (result[x.field] = available));
                        return result;
                    },
                    {},
                );

                const seen: Record<string, boolean> = {};
                this.selected = (this.params.metrics ?? []).reduce<IReportParamsMetrics[]>((result, x) => {
                    const field = typeof x === 'string' ? x : x.field;
                    const available = availableByField[field];
                    if (!available || seen[available.id]) return result;
                    result.push(available);
                    seen[available.id] = true;
                    return result;
                }, []);
            }
        };
    },
];
