import { Browser } from './services';
import { ConfigAPI } from './config-api';
import { IConfigExperiments, IConfigObj } from './types';
import { UserExperimentsStorage } from './storage-user-experiments';

export type ConfigPageExperiments = ReturnType<typeof normalize>;

export const normalize = (
    userLocalStorage: { experiments?: Partial<IConfigExperiments> },
    orgConfig: { experiments?: Partial<IConfigExperiments> },
    userInternalConfig?: { experiments?: Partial<IConfigExperiments> },
) => {
    const localStorageExperiments = userLocalStorage.experiments ?? {};
    const orgExperiments = orgConfig?.experiments ?? {};
    const userExperiments = userInternalConfig?.experiments ?? {};

    const get = (key: keyof IConfigExperiments, defaultValue: boolean | null = null): boolean | null => {
        return localStorageExperiments[key] ?? userExperiments[key] ?? orgExperiments[key] ?? defaultValue;
    };

    return {
        multiplePropertyGroupBy: get('enableMultiplePropertyGroupBy'),
        segmentsInMetricsPage: Browser.isLocalhost() && get('enableSegmentsInMetricsPage'),
        metricsCategorization: get('enableMetricsCategorization'),
        sidebar: get('enableSidebar'),
        // TODO: Remove these two flags once last version of sliding sidebar goes to "next-release" stage
        metricsInSidebar: get('enableSidebar'),
        groupByPropertiesInSidebar: get('enableSidebar'),
    };
};

export async function fetch(overrides?: { organization?: IConfigObj; user?: IConfigObj }) {
    return ConfigAPI.get().then(api => {
        return Promise.all([
            Promise.resolve(UserExperimentsStorage.get()).then(e => ({ experiments: e ?? {} })),
            overrides?.organization ?? api.organization.get(),
            overrides?.user ?? api.user.getInternal(),
        ]).then(([userLocalStorage, orgConfig, userConfig]) => {
            return normalize(userLocalStorage, orgConfig, userConfig);
        });
    });
}
