
module = angular.module('42.directives.charts.echarts.title', [])
export default module

module.directive 'chartEchartsTitle', ->
    restrict: 'E'
    replace: true
    scope:
        model: '='
    template: \
    """
    <h1 class="echarts-title card-title">
        <div ng-if="view.override">
            <span>{{ model }}</span>
        </div>
        <div ng-if="!view.override">
            <span class="metric" ng-repeat="x in model.metrics track by x.id">{{ x.label }}</span>
            <span ng-show="model.metrics.length > 0 && model.properties.length > 0">by</span>
            <span class="metric" ng-repeat="x in model.properties track by x.id">{{ x.label }}</span>
        </div>
    </h1>
    """
    ###* @param {any} scope ###
    link: (scope) ->
        scope.view = {}
        scope.$watch 'model', (model) -> scope.view.override = typeof model is 'string'
